import React, { useState } from 'react';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { Flex, Text, TextField, Button, Heading, useTheme } from '@aws-amplify/ui-react';
import { AuthState } from '../types/auth';

const purpleColor = '#B388FF';
const yellowColor = '#FFD54F';
const mediumSpacing = '1rem';

interface ConfirmSignUpProps {
    onStateChange: (state: AuthState, username?: string) => void;
    username: string;
}

const ConfirmSignUp: React.FC<ConfirmSignUpProps> = ({ onStateChange, username }) => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const { tokens } = useTheme();

    const handleConfirmSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await confirmSignUp({ username, confirmationCode: code });
            onStateChange(AuthState.SignIn);
        } catch (err: any) {
            setError(err.message || 'An error occurred during confirmation');
        }
    };

    const handleResendCode = async () => {
        try {
            await resendSignUpCode({ username });
            setError('');
            alert('Confirmation code resent. Check your email.');
        } catch (err: any) {
            setError(err.message || 'An error occurred while resending the code');
        }
    };

    return (
        <Flex direction="row" height="100vh">
            <Flex direction="column" flex="0 0 40%" padding={tokens.space.large} backgroundColor="white" justifyContent="center">
                <Flex direction="column" width="100%" maxWidth="400px" margin="0 auto">
                    <Heading level={3} padding={tokens.space.medium} textAlign="center">Confirm your account</Heading>
                    <form onSubmit={handleConfirmSignUp}>
                        <TextField
                            label="Confirmation Code"
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                        <Button type="submit" variation="primary" width="100%" marginTop={tokens.space.medium}>
                            Confirm
                        </Button>
                    </form>
                    <Button onClick={handleResendCode} variation="link" marginTop={tokens.space.small}>
                        Resend code
                    </Button>
                    <Text
                        color={purpleColor}
                        fontSize={tokens.fontSizes.small}
                        fontWeight={tokens.fontWeights.bold}
                        onClick={() => onStateChange(AuthState.SignIn)}
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                        marginTop={mediumSpacing}
                    >
                        Back to Sign In
                    </Text>
                    {error && (
                        <Text color={tokens.colors.red[60]} marginTop={tokens.space.medium}>
                            {error}
                        </Text>
                    )}
                </Flex>
            </Flex>
            {/* Branding message column (same as SignIn.tsx) */}
            <Flex
                direction="column"
                flex="0 0 60%"
                backgroundColor={"#233d4d"}
                padding={tokens.space.xl}
                justifyContent="center"
            >
                <Heading level={1} color={tokens.colors.white}>Join The Better Exit</Heading>
                <Text fontSize={tokens.fontSizes.large} color="#f4f4f9" marginTop={tokens.space.medium}>
                    Start your journey to a successful business exit with The Better Exit's comprehensive toolkit!
                </Text>
                <Text fontSize={tokens.fontSizes.xxl} fontWeight={tokens.fontWeights.bold} marginTop={tokens.space.xl}>
                    <span style={{ color: purpleColor }}>Stop waiting for someday, </span><br />
                    <span style={{ color: yellowColor }}>Start planning for Your Better Exit today!</span>
                </Text>
            </Flex>
        </Flex>
    );
};

export default ConfirmSignUp;