import React, { useState } from 'react';
import BusinessCreateForm, { BusinessCreateFormProps, BusinessCreateFormInputValues } from '../ui-components/BusinessCreateForm';
import { Button, Flex, View } from '@aws-amplify/ui-react';
import '../assets/css/BusiessCreateFormWizard.css';

interface BusinessCreateFormWizardProps extends Omit<BusinessCreateFormProps, 'onSubmit'> {
    onSubmit: (fields: BusinessCreateFormInputValues) => void;
    user: any; // Replace 'any' with the actual type of your user object
  }

const BusinessCreateFormWizard: React.FC<BusinessCreateFormWizardProps> = (props) => {
  const [formFields, setFormFields] = useState<BusinessCreateFormInputValues>({});
  const [currentStep, setCurrentStep] = useState(0);
  const fieldNames = ['name', 'industry', 'description', /* add all form fields here */];
  const totalSteps = fieldNames.length;

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, totalSteps - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleSubmit = (fields: BusinessCreateFormInputValues) => {
    setFormFields(fields);
    if (currentStep === totalSteps - 1) {
      props.onSubmit(fields);
    } else {
      nextStep();
    }
    return fields;
  };

  return (
    <View className="business-create-form-wizard">
      <BusinessCreateForm
        {...props}
        onSubmit={handleSubmit}
        overrides={{
          ...fieldNames.reduce((acc, key, index) => {
            acc[key] = {
              className: `wizard-step ${index === currentStep ? 'active' : ''}`,
            };
            return acc;
          }, {} as Record<string, { className: string }>),
          SubmitButton: { display: 'none' as const },
        }}
      />
      <Flex direction="row" justifyContent="space-between" marginTop="20px">
        <Button onClick={prevStep} isDisabled={currentStep === 0}>
          Previous
        </Button>
        {currentStep === totalSteps - 1 ? (
          <Button onClick={() => props.onSubmit(formFields)} variation="primary">
            Submit
          </Button>
        ) : (
          <Button onClick={nextStep}>Next</Button>
        )}
      </Flex>
    </View>
  );
};

export default BusinessCreateFormWizard;