/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $condition: ModelBusinessConditionInput
    $input: CreateBusinessInput!
  ) {
    createBusiness(condition: $condition, input: $input) {
      businessId
      businessOwner {
        company
        createdAt
        email
        id
        linkedInProfile
        name
        owner
        planLevel
        profilePicture
        stripe_api_key
        stripe_secret
        subscriptionStatus
        updatedAt
        userId
        userType
        __typename
      }
      createdAt
      currentAccountsReceivable
      currentAnnualRevenue
      currentCash
      currentCashCollectionCycleDays
      currentEbitda
      currentEbitdaMultiple
      currentEmployeeCount
      currentEquipmentAndFurniture
      currentGrossProfitMargin
      currentInventory
      currentNetOperatingIncome
      currentNetProfitMargin
      currentOwnerOperated
      currentRealEstate
      currentSde
      currentTotalAnnualPayroll
      currentTotalAssets
      currentTotalCurrentLiabilities
      currentTotalCustomersServeredPerYear
      currentTotalEquity
      currentTotalLiabilities
      currentTotalMarketingBudget
      currentTotalSalesBudget
      currentTotalTransactionsPerYear
      currentValuation
      description
      employeePlan
      goalsForSale
      id
      industry
      listingStatus
      managementPlan
      name
      originalAnnualRevenue
      originalCashCollectionCycleDays
      originalEbitda
      originalEbitdaMultiple
      originalEmployeeCount
      originalGrossProfitMargin
      originalNetOperatingIncome
      originalNetProfitMargin
      originalOwnerOperated
      originalSde
      originalTotalAnnualPayroll
      originalTotalAssets
      originalTotalCurrentLiabilities
      originalTotalCustomersServeredPerYear
      originalTotalEquity
      originalTotalLiabilities
      originalTotalMarketingBudget
      originalTotalSalesBudget
      originalTotalTransactionsPerYear
      originalValuation
      owner
      ownerId
      postExitVision
      reasonsForSelling
      targetAnnualRevenue
      targetCashCollectionCycleDays
      targetEbitda
      targetEbitdaMultiple
      targetEmployeeCount
      targetExitDate
      targetGrossProfitMargin
      targetNetOperatingIncome
      targetNetProfitMargin
      targetOwnerOperated
      targetSde
      targetTotalAnnualPayroll
      targetTotalAssets
      targetTotalCurrentLiabilities
      targetTotalCustomersServeredPerYear
      targetTotalEquity
      targetTotalLiabilities
      targetTotalMarketingBudget
      targetTotalSalesBudget
      targetTotalTransactionsPerYear
      targetValuation
      totalShares
      updatedAt
      whatWillHappenIfExit
      whatWillHappenIfNoExit
      whatWillNotHappenIfExit
      whatWillNotHappenIfNoExit
      yearFounded
      __typename
    }
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $condition: ModelTodoConditionInput
    $input: CreateTodoInput!
  ) {
    createTodo(condition: $condition, input: $input) {
      content
      createdAt
      id
      isDone
      key
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $condition: ModelUserConditionInput
    $input: CreateUserInput!
  ) {
    createUser(condition: $condition, input: $input) {
      company
      createdAt
      email
      id
      linkedInProfile
      name
      ownedBusinesses {
        nextToken
        __typename
      }
      owner
      planLevel
      profilePicture
      stripe_api_key
      stripe_secret
      subscriptionStatus
      updatedAt
      userId
      userType
      __typename
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $condition: ModelBusinessConditionInput
    $input: DeleteBusinessInput!
  ) {
    deleteBusiness(condition: $condition, input: $input) {
      businessId
      businessOwner {
        company
        createdAt
        email
        id
        linkedInProfile
        name
        owner
        planLevel
        profilePicture
        stripe_api_key
        stripe_secret
        subscriptionStatus
        updatedAt
        userId
        userType
        __typename
      }
      createdAt
      currentAccountsReceivable
      currentAnnualRevenue
      currentCash
      currentCashCollectionCycleDays
      currentEbitda
      currentEbitdaMultiple
      currentEmployeeCount
      currentEquipmentAndFurniture
      currentGrossProfitMargin
      currentInventory
      currentNetOperatingIncome
      currentNetProfitMargin
      currentOwnerOperated
      currentRealEstate
      currentSde
      currentTotalAnnualPayroll
      currentTotalAssets
      currentTotalCurrentLiabilities
      currentTotalCustomersServeredPerYear
      currentTotalEquity
      currentTotalLiabilities
      currentTotalMarketingBudget
      currentTotalSalesBudget
      currentTotalTransactionsPerYear
      currentValuation
      description
      employeePlan
      goalsForSale
      id
      industry
      listingStatus
      managementPlan
      name
      originalAnnualRevenue
      originalCashCollectionCycleDays
      originalEbitda
      originalEbitdaMultiple
      originalEmployeeCount
      originalGrossProfitMargin
      originalNetOperatingIncome
      originalNetProfitMargin
      originalOwnerOperated
      originalSde
      originalTotalAnnualPayroll
      originalTotalAssets
      originalTotalCurrentLiabilities
      originalTotalCustomersServeredPerYear
      originalTotalEquity
      originalTotalLiabilities
      originalTotalMarketingBudget
      originalTotalSalesBudget
      originalTotalTransactionsPerYear
      originalValuation
      owner
      ownerId
      postExitVision
      reasonsForSelling
      targetAnnualRevenue
      targetCashCollectionCycleDays
      targetEbitda
      targetEbitdaMultiple
      targetEmployeeCount
      targetExitDate
      targetGrossProfitMargin
      targetNetOperatingIncome
      targetNetProfitMargin
      targetOwnerOperated
      targetSde
      targetTotalAnnualPayroll
      targetTotalAssets
      targetTotalCurrentLiabilities
      targetTotalCustomersServeredPerYear
      targetTotalEquity
      targetTotalLiabilities
      targetTotalMarketingBudget
      targetTotalSalesBudget
      targetTotalTransactionsPerYear
      targetValuation
      totalShares
      updatedAt
      whatWillHappenIfExit
      whatWillHappenIfNoExit
      whatWillNotHappenIfExit
      whatWillNotHappenIfNoExit
      yearFounded
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $condition: ModelTodoConditionInput
    $input: DeleteTodoInput!
  ) {
    deleteTodo(condition: $condition, input: $input) {
      content
      createdAt
      id
      isDone
      key
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $condition: ModelUserConditionInput
    $input: DeleteUserInput!
  ) {
    deleteUser(condition: $condition, input: $input) {
      company
      createdAt
      email
      id
      linkedInProfile
      name
      ownedBusinesses {
        nextToken
        __typename
      }
      owner
      planLevel
      profilePicture
      stripe_api_key
      stripe_secret
      subscriptionStatus
      updatedAt
      userId
      userType
      __typename
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $condition: ModelBusinessConditionInput
    $input: UpdateBusinessInput!
  ) {
    updateBusiness(condition: $condition, input: $input) {
      businessId
      businessOwner {
        company
        createdAt
        email
        id
        linkedInProfile
        name
        owner
        planLevel
        profilePicture
        stripe_api_key
        stripe_secret
        subscriptionStatus
        updatedAt
        userId
        userType
        __typename
      }
      createdAt
      currentAccountsReceivable
      currentAnnualRevenue
      currentCash
      currentCashCollectionCycleDays
      currentEbitda
      currentEbitdaMultiple
      currentEmployeeCount
      currentEquipmentAndFurniture
      currentGrossProfitMargin
      currentInventory
      currentNetOperatingIncome
      currentNetProfitMargin
      currentOwnerOperated
      currentRealEstate
      currentSde
      currentTotalAnnualPayroll
      currentTotalAssets
      currentTotalCurrentLiabilities
      currentTotalCustomersServeredPerYear
      currentTotalEquity
      currentTotalLiabilities
      currentTotalMarketingBudget
      currentTotalSalesBudget
      currentTotalTransactionsPerYear
      currentValuation
      description
      employeePlan
      goalsForSale
      id
      industry
      listingStatus
      managementPlan
      name
      originalAnnualRevenue
      originalCashCollectionCycleDays
      originalEbitda
      originalEbitdaMultiple
      originalEmployeeCount
      originalGrossProfitMargin
      originalNetOperatingIncome
      originalNetProfitMargin
      originalOwnerOperated
      originalSde
      originalTotalAnnualPayroll
      originalTotalAssets
      originalTotalCurrentLiabilities
      originalTotalCustomersServeredPerYear
      originalTotalEquity
      originalTotalLiabilities
      originalTotalMarketingBudget
      originalTotalSalesBudget
      originalTotalTransactionsPerYear
      originalValuation
      owner
      ownerId
      postExitVision
      reasonsForSelling
      targetAnnualRevenue
      targetCashCollectionCycleDays
      targetEbitda
      targetEbitdaMultiple
      targetEmployeeCount
      targetExitDate
      targetGrossProfitMargin
      targetNetOperatingIncome
      targetNetProfitMargin
      targetOwnerOperated
      targetSde
      targetTotalAnnualPayroll
      targetTotalAssets
      targetTotalCurrentLiabilities
      targetTotalCustomersServeredPerYear
      targetTotalEquity
      targetTotalLiabilities
      targetTotalMarketingBudget
      targetTotalSalesBudget
      targetTotalTransactionsPerYear
      targetValuation
      totalShares
      updatedAt
      whatWillHappenIfExit
      whatWillHappenIfNoExit
      whatWillNotHappenIfExit
      whatWillNotHappenIfNoExit
      yearFounded
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $condition: ModelTodoConditionInput
    $input: UpdateTodoInput!
  ) {
    updateTodo(condition: $condition, input: $input) {
      content
      createdAt
      id
      isDone
      key
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $condition: ModelUserConditionInput
    $input: UpdateUserInput!
  ) {
    updateUser(condition: $condition, input: $input) {
      company
      createdAt
      email
      id
      linkedInProfile
      name
      ownedBusinesses {
        nextToken
        __typename
      }
      owner
      planLevel
      profilePicture
      stripe_api_key
      stripe_secret
      subscriptionStatus
      updatedAt
      userId
      userType
      __typename
    }
  }
`;
