import React from 'react';
import { Flex, Text } from "@aws-amplify/ui-react";

import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/css/main.css';

const FOOTER_HEIGHT = '60px';

const Footer: React.FC = () => {
  return (
    <Flex
      as="footer"
      direction="row"
      justifyContent="center"
      alignItems="center"
      padding="1rem"
      backgroundColor="var(--charcoal)"
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      height={FOOTER_HEIGHT}
    >
      <Flex className="col-lg-6">
        <Text className="sitename">&copy; 2024 TheBetterExit.com &trade; Melbourne Business Advisors. All rights reserved.</Text>
      </Flex>
      <Flex className="col-lg-4" as="nav" direction="row" gap="1rem" marginTop="0.5rem">
        <a href="/privacy">Privacy Policy</a>
        <a href="/terms">Terms of Service</a>
      </Flex>
    </Flex>
  );
};

export default Footer;