/* eslint-disable */
"use client";
import * as React from "react";
import { v4 as uuidv4 } from 'uuid';
import { industryOptions, industryEBITDAMultiples, industryGrossProfitMargins, industryNetProfitMargins, industryRevenueMultiples, industryProfitMultiples } from "../types/NYUStearnsData";
import CurrencyTextField from '../components/CurrencyTextField';

import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SelectField,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createBusiness } from "./graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function BusinessCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    user,
    ...rest
  } = props;
  const initialValues = {
    businessId: uuidv4(),
    name: "",
    industry: "",
    description: "",
    yearFounded: "",
    originalTotalCustomersServeredPerYear: "",
    originalTotalTransactionsPerYear: "",
    originalTotalMarketingBudget: "",
    originalTotalSalesBudget: "",
    originalTotalAnnualPayroll: "",
    originalAnnualRevenue: "",
    originalNetOperatingIncome: "",
    originalGrossProfitMargin: "",
    originalNetProfitMargin: "",
    originalTotalAssets: "",
    originalTotalLiabilities: "",
    originalTotalCurrentLiabilities: "",
    originalCashCollectionCycleDays: "",
    originalTotalEquity: "",
    originalSde: "",
    originalEbitda: "",
    originalEbitdaMultiple: "",
    originalEmployeeCount: "",
    originalValuation: "",
    originalOwnerOperated: false,
    ownerId: user?.id || "",
    currentTotalCustomersServeredPerYear: "",
    currentTotalTransactionsPerYear: "",
    currentTotalMarketingBudget: "",
    currentTotalSalesBudget: "",
    currentTotalAnnualPayroll: "",
    currentAnnualRevenue: "",
    currentNetOperatingIncome: "",
    currentGrossProfitMargin: "",
    currentNetProfitMargin: "",
    currentTotalAssets: "",
    currentCash: "",
    currentAccountsReceivable: "",
    currentInventory: "",
    currentEquipmentAndFurniture: "",
    currentRealEstate: "",
    currentTotalLiabilities: "",
    currentTotalCurrentLiabilities: "",
    currentCashCollectionCycleDays: "",
    currentTotalEquity: "",
    currentSde: "",
    currentEbitda: "",
    currentEbitdaMultiple: "",
    currentEmployeeCount: "",
    currentValuation: "",
    currentOwnerOperated: false,
    targetTotalCustomersServeredPerYear: "",
    targetTotalTransactionsPerYear: "",
    targetTotalMarketingBudget: "",
    targetTotalSalesBudget: "",
    targetTotalAnnualPayroll: "",
    targetAnnualRevenue: "",
    targetNetOperatingIncome: "",
    targetGrossProfitMargin: "",
    targetNetProfitMargin: "",
    targetTotalAssets: "",
    targetTotalLiabilities: "",
    targetTotalCurrentLiabilities: "",
    targetCashCollectionCycleDays: "",
    targetTotalEquity: "",
    targetSde: "",
    targetEbitda: "",
    targetEbitdaMultiple: "",
    targetEmployeeCount: "",
    targetValuation: "",
    targetOwnerOperated: false,
    targetExitDate: "",
    listingStatus: "",
    totalShares: "",
    reasonsForSelling: [],
    goalsForSale: [],
    postExitVision: [],
    managementPlan: [],
    employeePlan: [],
    whatWillHappenIfExit: [],
    whatWillHappenIfNoExit: [],
    whatWillNotHappenIfExit: [],
    whatWillNotHappenIfNoExit: [],
    createdAt: new Date().toISOString(),
    updatedAt: "",
  };
  
  const [businessId, setBusinessId] = React.useState(initialValues.businessId);
  const [name, setName] = React.useState(initialValues.name);
  const [industry, setIndustry] = React.useState(initialValues.industry);
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [yearFounded, setYearFounded] = React.useState(
    initialValues.yearFounded
  );
  const [
    originalTotalCustomersServeredPerYear,
    setOriginalTotalCustomersServeredPerYear,
  ] = React.useState(initialValues.originalTotalCustomersServeredPerYear);
  const [
    originalTotalTransactionsPerYear,
    setOriginalTotalTransactionsPerYear,
  ] = React.useState(initialValues.originalTotalTransactionsPerYear);
  const [originalTotalMarketingBudget, setOriginalTotalMarketingBudget] =
    React.useState(initialValues.originalTotalMarketingBudget);
  const [originalTotalSalesBudget, setOriginalTotalSalesBudget] =
    React.useState(initialValues.originalTotalSalesBudget);
  const [originalTotalAnnualPayroll, setOriginalTotalAnnualPayroll] =
    React.useState(initialValues.originalTotalAnnualPayroll);
  const [originalAnnualRevenue, setOriginalAnnualRevenue] = React.useState(
    initialValues.originalAnnualRevenue
  );
  const [originalNetOperatingIncome, setOriginalNetOperatingIncome] =
    React.useState(initialValues.originalNetOperatingIncome);
  const [originalGrossProfitMargin, setOriginalGrossProfitMargin] =
    React.useState(initialValues.originalGrossProfitMargin);
  const [originalNetProfitMargin, setOriginalNetProfitMargin] = React.useState(
    initialValues.originalNetProfitMargin
  );
  const [originalTotalAssets, setOriginalTotalAssets] = React.useState(
    initialValues.originalTotalAssets
  );
  const [originalTotalLiabilities, setOriginalTotalLiabilities] =
    React.useState(initialValues.originalTotalLiabilities);
  const [originalTotalCurrentLiabilities, setOriginalTotalCurrentLiabilities] =
    React.useState(initialValues.originalTotalCurrentLiabilities);
  const [originalCashCollectionCycleDays, setOriginalCashCollectionCycleDays] =
    React.useState(initialValues.originalCashCollectionCycleDays);
  const [originalTotalEquity, setOriginalTotalEquity] = React.useState(
    initialValues.originalTotalEquity
  );
  const [originalSde, setOriginalSde] = React.useState(
    initialValues.originalSde
  );
  const [originalEbitda, setOriginalEbitda] = React.useState(
    initialValues.originalEbitda
  );
  const [originalEbitdaMultiple, setOriginalEbitdaMultiple] = React.useState(
    initialValues.originalEbitdaMultiple
  );
  const [originalEmployeeCount, setOriginalEmployeeCount] = React.useState(
    initialValues.originalEmployeeCount
  );
  const [originalValuation, setOriginalValuation] = React.useState(
    initialValues.originalValuation
  );
  const [originalOwnerOperated, setOriginalOwnerOperated] = React.useState(
    initialValues.originalOwnerOperated
  );
  const [
    currentTotalCustomersServeredPerYear,
    setCurrentTotalCustomersServeredPerYear,
  ] = React.useState(initialValues.currentTotalCustomersServeredPerYear);
  const [currentTotalTransactionsPerYear, setCurrentTotalTransactionsPerYear] =
    React.useState(initialValues.currentTotalTransactionsPerYear);
  const [currentTotalMarketingBudget, setCurrentTotalMarketingBudget] =
    React.useState(initialValues.currentTotalMarketingBudget);
  const [currentTotalSalesBudget, setCurrentTotalSalesBudget] = React.useState(
    initialValues.currentTotalSalesBudget
  );
  const [currentTotalAnnualPayroll, setCurrentTotalAnnualPayroll] =
    React.useState(initialValues.currentTotalAnnualPayroll);
  const [currentAnnualRevenue, setCurrentAnnualRevenue] = React.useState(
    initialValues.currentAnnualRevenue
  );
  const [currentNetOperatingIncome, setCurrentNetOperatingIncome] =
    React.useState(initialValues.currentNetOperatingIncome);
  const [currentGrossProfitMargin, setCurrentGrossProfitMargin] =
    React.useState(initialValues.currentGrossProfitMargin);
  const [currentNetProfitMargin, setCurrentNetProfitMargin] = React.useState(
    initialValues.currentNetProfitMargin
  );
  const [currentTotalAssets, setCurrentTotalAssets] = React.useState(
    initialValues.currentTotalAssets
  );
  const [currentCash, setCurrentCash] = React.useState(
    initialValues.currentCash
  );
  const [currentAccountsReceivable, setCurrentAccountsReceivable] =
    React.useState(initialValues.currentAccountsReceivable);
  const [currentInventory, setCurrentInventory] = React.useState(
    initialValues.currentInventory
  );
  const [currentEquipmentAndFurniture, setCurrentEquipmentAndFurniture] =
    React.useState(initialValues.currentEquipmentAndFurniture);
  const [currentRealEstate, setCurrentRealEstate] = React.useState(
    initialValues.currentRealEstate
  );
  const [currentTotalLiabilities, setCurrentTotalLiabilities] = React.useState(
    initialValues.currentTotalLiabilities
  );
  const [currentTotalCurrentLiabilities, setCurrentTotalCurrentLiabilities] =
    React.useState(initialValues.currentTotalCurrentLiabilities);
  const [currentCashCollectionCycleDays, setCurrentCashCollectionCycleDays] =
    React.useState(initialValues.currentCashCollectionCycleDays);
  const [currentTotalEquity, setCurrentTotalEquity] = React.useState(
    initialValues.currentTotalEquity
  );
  const [currentSde, setCurrentSde] = React.useState(initialValues.currentSde);
  const [currentEbitda, setCurrentEbitda] = React.useState(
    initialValues.currentEbitda
  );
  const [currentEbitdaMultiple, setCurrentEbitdaMultiple] = React.useState(
    initialValues.currentEbitdaMultiple
  );
  const [currentEmployeeCount, setCurrentEmployeeCount] = React.useState(
    initialValues.currentEmployeeCount
  );
  const [currentValuation, setCurrentValuation] = React.useState(
    initialValues.currentValuation
  );
  const [currentOwnerOperated, setCurrentOwnerOperated] = React.useState(
    initialValues.currentOwnerOperated
  );
  const [
    targetTotalCustomersServeredPerYear,
    setTargetTotalCustomersServeredPerYear,
  ] = React.useState(initialValues.targetTotalCustomersServeredPerYear);
  const [targetTotalTransactionsPerYear, setTargetTotalTransactionsPerYear] =
    React.useState(initialValues.targetTotalTransactionsPerYear);
  const [targetTotalMarketingBudget, setTargetTotalMarketingBudget] =
    React.useState(initialValues.targetTotalMarketingBudget);
  const [targetTotalSalesBudget, setTargetTotalSalesBudget] = React.useState(
    initialValues.targetTotalSalesBudget
  );
  const [targetTotalAnnualPayroll, setTargetTotalAnnualPayroll] =
    React.useState(initialValues.targetTotalAnnualPayroll);
  const [targetAnnualRevenue, setTargetAnnualRevenue] = React.useState(
    initialValues.targetAnnualRevenue
  );
  const [targetNetOperatingIncome, setTargetNetOperatingIncome] =
    React.useState(initialValues.targetNetOperatingIncome);
  const [targetGrossProfitMargin, setTargetGrossProfitMargin] = React.useState(
    initialValues.targetGrossProfitMargin
  );
  const [targetNetProfitMargin, setTargetNetProfitMargin] = React.useState(
    initialValues.targetNetProfitMargin
  );
  const [targetTotalAssets, setTargetTotalAssets] = React.useState(
    initialValues.targetTotalAssets
  );
  const [targetTotalLiabilities, setTargetTotalLiabilities] = React.useState(
    initialValues.targetTotalLiabilities
  );
  const [targetTotalCurrentLiabilities, setTargetTotalCurrentLiabilities] =
    React.useState(initialValues.targetTotalCurrentLiabilities);
  const [targetCashCollectionCycleDays, setTargetCashCollectionCycleDays] =
    React.useState(initialValues.targetCashCollectionCycleDays);
  const [targetTotalEquity, setTargetTotalEquity] = React.useState(
    initialValues.targetTotalEquity
  );
  const [targetSde, setTargetSde] = React.useState(initialValues.targetSde);
  const [targetEbitda, setTargetEbitda] = React.useState(
    initialValues.targetEbitda
  );
  const [targetEbitdaMultiple, setTargetEbitdaMultiple] = React.useState(
    initialValues.targetEbitdaMultiple
  );
  const [targetEmployeeCount, setTargetEmployeeCount] = React.useState(
    initialValues.targetEmployeeCount
  );
  const [targetValuation, setTargetValuation] = React.useState(
    initialValues.targetValuation
  );
  const [targetOwnerOperated, setTargetOwnerOperated] = React.useState(
    initialValues.targetOwnerOperated
  );
  const [targetExitDate, setTargetExitDate] = React.useState(
    initialValues.targetExitDate
  );
  const [listingStatus, setListingStatus] = React.useState(
    initialValues.listingStatus
  );
  const [totalShares, setTotalShares] = React.useState(
    initialValues.totalShares
  );
  const [reasonsForSelling, setReasonsForSelling] = React.useState(
    initialValues.reasonsForSelling
  );
  const [goalsForSale, setGoalsForSale] = React.useState(
    initialValues.goalsForSale
  );
  const [postExitVision, setPostExitVision] = React.useState(
    initialValues.postExitVision
  );
  const [managementPlan, setManagementPlan] = React.useState(
    initialValues.managementPlan
  );
  const [employeePlan, setEmployeePlan] = React.useState(
    initialValues.employeePlan
  );
  const [whatWillHappenIfExit, setWhatWillHappenIfExit] = React.useState(
    initialValues.whatWillHappenIfExit
  );
  const [whatWillHappenIfNoExit, setWhatWillHappenIfNoExit] = React.useState(
    initialValues.whatWillHappenIfNoExit
  );
  const [whatWillNotHappenIfExit, setWhatWillNotHappenIfExit] = React.useState(
    initialValues.whatWillNotHappenIfExit
  );
  const [whatWillNotHappenIfNoExit, setWhatWillNotHappenIfNoExit] =
    React.useState(initialValues.whatWillNotHappenIfNoExit);
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [updatedAt, setUpdatedAt] = React.useState(initialValues.updatedAt);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBusinessId(initialValues.businessId);
    setName(initialValues.name);
    setIndustry(initialValues.industry);
    setDescription(initialValues.description);
    setYearFounded(initialValues.yearFounded);
    setOriginalTotalCustomersServeredPerYear(
      initialValues.originalTotalCustomersServeredPerYear
    );
    setOriginalTotalTransactionsPerYear(
      initialValues.originalTotalTransactionsPerYear
    );
    setOriginalTotalMarketingBudget(initialValues.originalTotalMarketingBudget);
    setOriginalTotalSalesBudget(initialValues.originalTotalSalesBudget);
    setOriginalTotalAnnualPayroll(initialValues.originalTotalAnnualPayroll);
    setOriginalAnnualRevenue(initialValues.originalAnnualRevenue);
    setOriginalNetOperatingIncome(initialValues.originalNetOperatingIncome);
    setOriginalGrossProfitMargin(initialValues.originalGrossProfitMargin);
    setOriginalNetProfitMargin(initialValues.originalNetProfitMargin);
    setOriginalTotalAssets(initialValues.originalTotalAssets);
    setOriginalTotalLiabilities(initialValues.originalTotalLiabilities);
    setOriginalTotalCurrentLiabilities(
      initialValues.originalTotalCurrentLiabilities
    );
    setOriginalCashCollectionCycleDays(
      initialValues.originalCashCollectionCycleDays
    );
    setOriginalTotalEquity(initialValues.originalTotalEquity);
    setOriginalSde(initialValues.originalSde);
    setOriginalEbitda(initialValues.originalEbitda);
    setOriginalEbitdaMultiple(initialValues.originalEbitdaMultiple);
    setOriginalEmployeeCount(initialValues.originalEmployeeCount);
    setOriginalValuation(initialValues.originalValuation);
    setOriginalOwnerOperated(initialValues.originalOwnerOperated);
    setCurrentTotalCustomersServeredPerYear(
      initialValues.currentTotalCustomersServeredPerYear
    );
    setCurrentTotalTransactionsPerYear(
      initialValues.currentTotalTransactionsPerYear
    );
    setCurrentTotalMarketingBudget(initialValues.currentTotalMarketingBudget);
    setCurrentTotalSalesBudget(initialValues.currentTotalSalesBudget);
    setCurrentTotalAnnualPayroll(initialValues.currentTotalAnnualPayroll);
    setCurrentAnnualRevenue(initialValues.currentAnnualRevenue);
    setCurrentNetOperatingIncome(initialValues.currentNetOperatingIncome);
    setCurrentGrossProfitMargin(initialValues.currentGrossProfitMargin);
    setCurrentNetProfitMargin(initialValues.currentNetProfitMargin);
    setCurrentTotalAssets(initialValues.currentTotalAssets);
    setCurrentCash(initialValues.currentCash);
    setCurrentAccountsReceivable(initialValues.currentAccountsReceivable);
    setCurrentInventory(initialValues.currentInventory);
    setCurrentEquipmentAndFurniture(initialValues.currentEquipmentAndFurniture);
    setCurrentRealEstate(initialValues.currentRealEstate);
    setCurrentTotalLiabilities(initialValues.currentTotalLiabilities);
    setCurrentTotalCurrentLiabilities(
      initialValues.currentTotalCurrentLiabilities
    );
    setCurrentCashCollectionCycleDays(
      initialValues.currentCashCollectionCycleDays
    );
    setCurrentTotalEquity(initialValues.currentTotalEquity);
    setCurrentSde(initialValues.currentSde);
    setCurrentEbitda(initialValues.currentEbitda);
    setCurrentEbitdaMultiple(initialValues.currentEbitdaMultiple);
    setCurrentEmployeeCount(initialValues.currentEmployeeCount);
    setCurrentValuation(initialValues.currentValuation);
    setCurrentOwnerOperated(initialValues.currentOwnerOperated);
    setTargetTotalCustomersServeredPerYear(
      initialValues.targetTotalCustomersServeredPerYear
    );
    setTargetTotalTransactionsPerYear(
      initialValues.targetTotalTransactionsPerYear
    );
    setTargetTotalMarketingBudget(initialValues.targetTotalMarketingBudget);
    setTargetTotalSalesBudget(initialValues.targetTotalSalesBudget);
    setTargetTotalAnnualPayroll(initialValues.targetTotalAnnualPayroll);
    setTargetAnnualRevenue(initialValues.targetAnnualRevenue);
    setTargetNetOperatingIncome(initialValues.targetNetOperatingIncome);
    setTargetGrossProfitMargin(initialValues.targetGrossProfitMargin);
    setTargetNetProfitMargin(initialValues.targetNetProfitMargin);
    setTargetTotalAssets(initialValues.targetTotalAssets);
    setTargetTotalLiabilities(initialValues.targetTotalLiabilities);
    setTargetTotalCurrentLiabilities(
      initialValues.targetTotalCurrentLiabilities
    );
    setTargetCashCollectionCycleDays(
      initialValues.targetCashCollectionCycleDays
    );
    setTargetTotalEquity(initialValues.targetTotalEquity);
    setTargetSde(initialValues.targetSde);
    setTargetEbitda(initialValues.targetEbitda);
    setTargetEbitdaMultiple(initialValues.targetEbitdaMultiple);
    setTargetEmployeeCount(initialValues.targetEmployeeCount);
    setTargetValuation(initialValues.targetValuation);
    setTargetOwnerOperated(initialValues.targetOwnerOperated);
    setTargetExitDate(initialValues.targetExitDate);
    setListingStatus(initialValues.listingStatus);
    setTotalShares(initialValues.totalShares);
    setReasonsForSelling(initialValues.reasonsForSelling);
    setCurrentReasonsForSellingValue("");
    setGoalsForSale(initialValues.goalsForSale);
    setCurrentGoalsForSaleValue("");
    setPostExitVision(initialValues.postExitVision);
    setCurrentPostExitVisionValue("");
    setManagementPlan(initialValues.managementPlan);
    setCurrentManagementPlanValue("");
    setEmployeePlan(initialValues.employeePlan);
    setCurrentEmployeePlanValue("");
    setWhatWillHappenIfExit(initialValues.whatWillHappenIfExit);
    setCurrentWhatWillHappenIfExitValue("");
    setWhatWillHappenIfNoExit(initialValues.whatWillHappenIfNoExit);
    setCurrentWhatWillHappenIfNoExitValue("");
    setWhatWillNotHappenIfExit(initialValues.whatWillNotHappenIfExit);
    setCurrentWhatWillNotHappenIfExitValue("");
    setWhatWillNotHappenIfNoExit(initialValues.whatWillNotHappenIfNoExit);
    setCurrentWhatWillNotHappenIfNoExitValue("");
    setCreatedAt(initialValues.createdAt);
    setUpdatedAt(initialValues.updatedAt);
    setErrors({});
  };
  const [currentReasonsForSellingValue, setCurrentReasonsForSellingValue] =
    React.useState("");
  const reasonsForSellingRef = React.createRef();
  const [currentGoalsForSaleValue, setCurrentGoalsForSaleValue] =
    React.useState("");
  const goalsForSaleRef = React.createRef();
  const [currentPostExitVisionValue, setCurrentPostExitVisionValue] =
    React.useState("");
  const postExitVisionRef = React.createRef();
  const [currentManagementPlanValue, setCurrentManagementPlanValue] =
    React.useState("");
  const managementPlanRef = React.createRef();
  const [currentEmployeePlanValue, setCurrentEmployeePlanValue] =
    React.useState("");
  const employeePlanRef = React.createRef();
  const [
    currentWhatWillHappenIfExitValue,
    setCurrentWhatWillHappenIfExitValue,
  ] = React.useState("");
  const whatWillHappenIfExitRef = React.createRef();
  const [
    currentWhatWillHappenIfNoExitValue,
    setCurrentWhatWillHappenIfNoExitValue,
  ] = React.useState("");
  const whatWillHappenIfNoExitRef = React.createRef();
  const [
    currentWhatWillNotHappenIfExitValue,
    setCurrentWhatWillNotHappenIfExitValue,
  ] = React.useState("");
  const whatWillNotHappenIfExitRef = React.createRef();
  const [
    currentWhatWillNotHappenIfNoExitValue,
    setCurrentWhatWillNotHappenIfNoExitValue,
  ] = React.useState("");
  const whatWillNotHappenIfNoExitRef = React.createRef();
  const validations = {
    businessId: [{ type: "Required" }],
    name: [{ type: "Required" }],
    industry: [{ type: "Required" }],
    description: [{ type: "Required" }],
    yearFounded: [],
    originalTotalCustomersServeredPerYear: [],
    originalTotalTransactionsPerYear: [],
    originalTotalMarketingBudget: [],
    originalTotalSalesBudget: [],
    originalTotalAnnualPayroll: [],
    originalAnnualRevenue: [],
    originalNetOperatingIncome: [],
    originalGrossProfitMargin: [],
    originalNetProfitMargin: [],
    originalTotalAssets: [],
    originalTotalLiabilities: [],
    originalTotalCurrentLiabilities: [],
    originalCashCollectionCycleDays: [],
    originalTotalEquity: [],
    originalSde: [],
    originalEbitda: [],
    originalEbitdaMultiple: [],
    originalEmployeeCount: [],
    originalValuation: [],
    originalOwnerOperated: [],
    currentTotalCustomersServeredPerYear: [],
    currentTotalTransactionsPerYear: [],
    currentTotalMarketingBudget: [],
    currentTotalSalesBudget: [],
    currentTotalAnnualPayroll: [],
    currentAnnualRevenue: [],
    currentNetOperatingIncome: [],
    currentGrossProfitMargin: [],
    currentNetProfitMargin: [],
    currentTotalAssets: [],
    currentCash: [],
    currentAccountsReceivable: [],
    currentInventory: [],
    currentEquipmentAndFurniture: [],
    currentRealEstate: [],
    currentTotalLiabilities: [],
    currentTotalCurrentLiabilities: [],
    currentCashCollectionCycleDays: [],
    currentTotalEquity: [],
    currentSde: [],
    currentEbitda: [],
    currentEbitdaMultiple: [],
    currentEmployeeCount: [],
    currentValuation: [],
    currentOwnerOperated: [],
    targetTotalCustomersServeredPerYear: [],
    targetTotalTransactionsPerYear: [],
    targetTotalMarketingBudget: [],
    targetTotalSalesBudget: [],
    targetTotalAnnualPayroll: [],
    targetAnnualRevenue: [],
    targetNetOperatingIncome: [],
    targetGrossProfitMargin: [],
    targetNetProfitMargin: [],
    targetTotalAssets: [],
    targetTotalLiabilities: [],
    targetTotalCurrentLiabilities: [],
    targetCashCollectionCycleDays: [],
    targetTotalEquity: [],
    targetSde: [],
    targetEbitda: [],
    targetEbitdaMultiple: [],
    targetEmployeeCount: [],
    targetValuation: [],
    targetOwnerOperated: [],
    targetExitDate: [],
    listingStatus: [],
    totalShares: [],
    reasonsForSelling: [],
    goalsForSale: [],
    postExitVision: [],
    managementPlan: [],
    employeePlan: [],
    whatWillHappenIfExit: [],
    whatWillHappenIfNoExit: [],
    whatWillNotHappenIfExit: [],
    whatWillNotHappenIfNoExit: [],
    createdAt: [{ type: "Required" }],
    updatedAt: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);

    // If it's a currency field, try parsing it as a number
    if (['originalTotalMarketingBudget',
       'originalTotalSalesBudget',
       'originalTotalAnnualPayroll',
       'originalAnnualRevenue',
       'originalNetOperatingIncome',
       'originalTotalAssets',
       'originalTotalLiabilities',
       'originalTotalCurrentAssets',
       'originalTotalCurrentLiabilities',
       'originalTotalEquity',
       'originalSde',
       'originalEbitda',
       'originalValuation',
       'targetTotalMarketingBudget',
       'targetTotalSalesBudget',
       'targetTotalAnnualPayroll',
       'targetAnnualRevenue',
       'targetNetOperatingIncome',
       'targetTotalAssets',
       'targetTotalLiabilities',
       'targetTotalCurrentLiabilities',
       'targetTotalEquity',
       'targetSde',
       'targetEbitda',
       'targetValuation',
        /* other currency fields */].includes(fieldName)) {
      const numericValue = parseFloat(currentValue);
      if (!isNaN(numericValue)) {
        validationResponse = validateField(numericValue, validations[fieldName]);
      }
    }

    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      width="480px"
      maxWidth="480px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          businessId,
          name,
          industry,
          description,
          yearFounded,
          originalTotalCustomersServeredPerYear,
          originalTotalTransactionsPerYear,
          originalTotalMarketingBudget,
          originalTotalSalesBudget,
          originalTotalAnnualPayroll,
          originalAnnualRevenue,
          originalNetOperatingIncome,
          originalGrossProfitMargin,
          originalNetProfitMargin,
          originalTotalAssets,
          originalTotalLiabilities,
          originalTotalCurrentLiabilities,
          originalCashCollectionCycleDays,
          originalTotalEquity,
          originalSde,
          originalEbitda,
          originalEbitdaMultiple,
          originalEmployeeCount,
          originalValuation,
          originalOwnerOperated,
          ownerId: user?.id || "",
          currentTotalCustomersServeredPerYear,
          currentTotalTransactionsPerYear,
          currentTotalMarketingBudget,
          currentTotalSalesBudget,
          currentTotalAnnualPayroll,
          currentAnnualRevenue,
          currentNetOperatingIncome,
          currentGrossProfitMargin,
          currentNetProfitMargin,
          currentTotalAssets,
          currentCash,
          currentAccountsReceivable,
          currentInventory,
          currentEquipmentAndFurniture,
          currentRealEstate,
          currentTotalLiabilities,
          currentTotalCurrentLiabilities,
          currentCashCollectionCycleDays,
          currentTotalEquity,
          currentSde,
          currentEbitda,
          currentEbitdaMultiple,
          currentEmployeeCount,
          currentValuation,
          currentOwnerOperated,
          targetTotalCustomersServeredPerYear,
          targetTotalTransactionsPerYear,
          targetTotalMarketingBudget,
          targetTotalSalesBudget,
          targetTotalAnnualPayroll,
          targetAnnualRevenue,
          targetNetOperatingIncome,
          targetGrossProfitMargin,
          targetNetProfitMargin,
          targetTotalAssets,
          targetTotalLiabilities,
          targetTotalCurrentLiabilities,
          targetCashCollectionCycleDays,
          targetTotalEquity,
          targetSde,
          targetEbitda,
          targetEbitdaMultiple,
          targetEmployeeCount,
          targetValuation,
          targetOwnerOperated,
          targetExitDate,
          listingStatus,
          totalShares,
          reasonsForSelling,
          goalsForSale,
          postExitVision,
          managementPlan,
          employeePlan,
          whatWillHappenIfExit,
          whatWillHappenIfNoExit,
          whatWillNotHappenIfExit,
          whatWillNotHappenIfNoExit,
          createdAt,
          updatedAt,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createBusiness.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
                ownerId: user.id,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "BusinessCreateForm")}
      {...rest}
    >
      <TextField
        //label="Business id"
        required={true}
        readOnly={false}
        hidden={true}
        value={businessId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              businessId: value,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.businessId ?? value;
          }
          if (errors.businessId?.hasError) {
            runValidationTasks("businessId", value);
          }
          setBusinessId(value);
        }}
        onBlur={() => runValidationTasks("businessId", businessId)}
        errorMessage={errors.businessId?.errorMessage}
        hasError={errors.businessId?.hasError}
        {...getOverrideProps(overrides, "businessId")}
      ></TextField>
      <TextField
        label="Business Name"
        required={true}
        readOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              businessId,
              name: value,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <SelectField
        label="Industry"
        required={true}
        readOnly={false}
        value={industry}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry: value,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple: industryEBITDAMultiples[value]*0.60 || "",
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple: industryEBITDAMultiples[value]*0.60 || "",
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple: industryEBITDAMultiples[value] || "",
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.industry ?? value;
          }
          if (errors.industry?.hasError) {
            runValidationTasks("industry", value);
          }
          setIndustry(value);
          setOriginalEbitdaMultiple(industryEBITDAMultiples[value]*0.60 || "");
          setCurrentEbitdaMultiple(industryEBITDAMultiples[value]*0.60 || "");
          setTargetEbitdaMultiple(industryEBITDAMultiples[value] || "");

          if (industry && originalEbitda) setOriginalValuation(originalEbitda * industryEBITDAMultiples[industry]*.60);
          if (industry && currentEbitda) setCurrentValuation(originalEbitda * industryEBITDAMultiples[industry]*.60);
        }}
        onBlur={() => runValidationTasks("industry", industry)}
        errorMessage={errors.industry?.errorMessage}
        hasError={errors.industry?.hasError}
        {...getOverrideProps(overrides, "industry")}
      >
        {industryOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectField>
      <TextField
        label="In your own words, how would you describe your business?"
        required={true}
        readOnly={false}
        value={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description: value,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextField>
      <TextField
        label="In what year did you start your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={yearFounded}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded: value,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.yearFounded ?? value;
          }
          if (errors.yearFounded?.hasError) {
            runValidationTasks("yearFounded", value);
          }
          setYearFounded(value);
        }}
        onBlur={() => runValidationTasks("yearFounded", yearFounded)}
        errorMessage={errors.yearFounded?.errorMessage}
        hasError={errors.yearFounded?.hasError}
        {...getOverrideProps(overrides, "yearFounded")}
      ></TextField>
      <TextField
        label="How many customers have you served over the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalCustomersServeredPerYear}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear: value,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear: value,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalCustomersServeredPerYear ?? value;
          }
          if (errors.originalTotalCustomersServeredPerYear?.hasError) {
            runValidationTasks("originalTotalCustomersServeredPerYear", value);
          }
          setOriginalTotalCustomersServeredPerYear(value);
          setCurrentTotalCustomersServeredPerYear(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalTotalCustomersServeredPerYear",
            originalTotalCustomersServeredPerYear
          )
        }
        errorMessage={
          errors.originalTotalCustomersServeredPerYear?.errorMessage
        }
        hasError={errors.originalTotalCustomersServeredPerYear?.hasError}
        {...getOverrideProps(
          overrides,
          "originalTotalCustomersServeredPerYear"
        )}
      ></TextField>
      <TextField
        label="How many customer transactions occurred over the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalTransactionsPerYear}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear: value,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear: value,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalTransactionsPerYear ?? value;
          }
          if (errors.originalTotalTransactionsPerYear?.hasError) {
            runValidationTasks("originalTotalTransactionsPerYear", value);
          }
          setOriginalTotalTransactionsPerYear(value);
          setCurrentTotalTransactionsPerYear(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalTotalTransactionsPerYear",
            originalTotalTransactionsPerYear
          )
        }
        errorMessage={errors.originalTotalTransactionsPerYear?.errorMessage}
        hasError={errors.originalTotalTransactionsPerYear?.hasError}
        {...getOverrideProps(overrides, "originalTotalTransactionsPerYear")}
      ></TextField>
      <CurrencyTextField
        label="What was your total marketing spend for the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalMarketingBudget}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget: value,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget: value,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalMarketingBudget ?? value;
          }
          // if (errors.originalTotalMarketingBudget?.hasError) {
          //   runValidationTasks("originalTotalMarketingBudget", value);
          // }
          setOriginalTotalMarketingBudget(value);
          setCurrentTotalMarketingBudget(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalTotalMarketingBudget",
            originalTotalMarketingBudget
          )
        }
        errorMessage={errors.originalTotalMarketingBudget?.errorMessage}
        hasError={errors.originalTotalMarketingBudget?.hasError}
        {...getOverrideProps(overrides, "originalTotalMarketingBudget")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your total sales budget for the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalSalesBudget}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget: value,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget: value,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalSalesBudget ?? value;
          }
          if (errors.originalTotalSalesBudget?.hasError) {
            runValidationTasks("originalTotalSalesBudget", value);
          }
          setOriginalTotalSalesBudget(value);
          setCurrentTotalSalesBudget(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalTotalSalesBudget",
            originalTotalSalesBudget
          )
        }
        errorMessage={errors.originalTotalSalesBudget?.errorMessage}
        hasError={errors.originalTotalSalesBudget?.hasError}
        {...getOverrideProps(overrides, "originalTotalSalesBudget")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your total payroll expense for the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalAnnualPayroll}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll: value,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll: value,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalAnnualPayroll ?? value;
          }
          if (errors.originalTotalAnnualPayroll?.hasError) {
            runValidationTasks("originalTotalAnnualPayroll", value);
          }
          setOriginalTotalAnnualPayroll(value);
          setCurrentTotalAnnualPayroll(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalTotalAnnualPayroll",
            originalTotalAnnualPayroll
          )
        }
        errorMessage={errors.originalTotalAnnualPayroll?.errorMessage}
        hasError={errors.originalTotalAnnualPayroll?.hasError}
        {...getOverrideProps(overrides, "originalTotalAnnualPayroll")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your total revenue for the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalAnnualRevenue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue: value,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue: value,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalAnnualRevenue ?? value;
          }
          if (errors.originalAnnualRevenue?.hasError) {
            runValidationTasks("originalAnnualRevenue", value);
          }
          setOriginalAnnualRevenue(value);
          setCurrentAnnualRevenue(value);
        }}
        onBlur={() =>
          runValidationTasks("originalAnnualRevenue", originalAnnualRevenue)
        }
        errorMessage={errors.originalAnnualRevenue?.errorMessage}
        hasError={errors.originalAnnualRevenue?.hasError}
        {...getOverrideProps(overrides, "originalAnnualRevenue")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What was your net operating income for the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalNetOperatingIncome}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome: value,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome: value,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalNetOperatingIncome ?? value;
          }
          if (errors.originalNetOperatingIncome?.hasError) {
            runValidationTasks("originalNetOperatingIncome", value);
          }
          setOriginalNetOperatingIncome(value);
          setCurrentNetOperatingIncome(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalNetOperatingIncome",
            originalNetOperatingIncome
          )
        }
        errorMessage={errors.originalNetOperatingIncome?.errorMessage}
        hasError={errors.originalNetOperatingIncome?.hasError}
        {...getOverrideProps(overrides, "originalNetOperatingIncome")}
      ></CurrencyTextField>
      <TextField
        label="What was your Gross Profit Margin for the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalGrossProfitMargin}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin: value,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin: value,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalGrossProfitMargin ?? value;
          }
          if (errors.originalGrossProfitMargin?.hasError) {
            runValidationTasks("originalGrossProfitMargin", value);
          }
          setOriginalGrossProfitMargin(value);
          setCurrentGrossProfitMargin(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalGrossProfitMargin",
            originalGrossProfitMargin
          )
        }
        errorMessage={errors.originalGrossProfitMargin?.errorMessage}
        hasError={errors.originalGrossProfitMargin?.hasError}
        {...getOverrideProps(overrides, "originalGrossProfitMargin")}
      ></TextField>
      <TextField
        label="What was your Net Profit Margin over the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalNetProfitMargin}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin: value,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin: value,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalNetProfitMargin ?? value;
          }
          if (errors.originalNetProfitMargin?.hasError) {
            runValidationTasks("originalNetProfitMargin", value);
          }
          setOriginalNetProfitMargin(value);
          setCurrentNetProfitMargin(value);
        }}
        onBlur={() =>
          runValidationTasks("originalNetProfitMargin", originalNetProfitMargin)
        }
        errorMessage={errors.originalNetProfitMargin?.errorMessage}
        hasError={errors.originalNetProfitMargin?.hasError}
        {...getOverrideProps(overrides, "originalNetProfitMargin")}
      ></TextField>
      <CurrencyTextField
        label="What does your balance sheet report as your Total Assets?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalAssets}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets: value,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets: value,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalAssets ?? value;
          }
          if (errors.originalTotalAssets?.hasError) {
            runValidationTasks("originalTotalAssets", value);
          }
          setOriginalTotalAssets(value);
          setCurrentTotalAssets(value);
        }}
        onBlur={() =>
          runValidationTasks("originalTotalAssets", originalTotalAssets)
        }
        errorMessage={errors.originalTotalAssets?.errorMessage}
        hasError={errors.originalTotalAssets?.hasError}
        {...getOverrideProps(overrides, "originalTotalAssets")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What does your balance sheet report for Total Liabilities"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalLiabilities}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities: value,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities: value,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalLiabilities ?? value;
          }
          if (errors.originalTotalLiabilities?.hasError) {
            runValidationTasks("originalTotalLiabilities", value);
          }
          setOriginalTotalLiabilities(value);
          setCurrentTotalLiabilities(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalTotalLiabilities",
            originalTotalLiabilities
          )
        }
        errorMessage={errors.originalTotalLiabilities?.errorMessage}
        hasError={errors.originalTotalLiabilities?.hasError}
        {...getOverrideProps(overrides, "originalTotalLiabilities")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What does your balance sheet report as your Current Liabilities?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalCurrentLiabilities}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities: value,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities: value,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalCurrentLiabilities ?? value;
          }
          if (errors.originalTotalCurrentLiabilities?.hasError) {
            runValidationTasks("originalTotalCurrentLiabilities", value);
          }
          setOriginalTotalCurrentLiabilities(value);
          setCurrentTotalCurrentLiabilities(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalTotalCurrentLiabilities",
            originalTotalCurrentLiabilities
          )
        }
        errorMessage={errors.originalTotalCurrentLiabilities?.errorMessage}
        hasError={errors.originalTotalCurrentLiabilities?.hasError}
        {...getOverrideProps(overrides, "originalTotalCurrentLiabilities")}
      ></CurrencyTextField>
      <TextField
        label="What is your Cash Collection Cycle in days?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalCashCollectionCycleDays}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays: value,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays: value,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalCashCollectionCycleDays ?? value;
          }
          if (errors.originalCashCollectionCycleDays?.hasError) {
            runValidationTasks("originalCashCollectionCycleDays", value);
          }
          setOriginalCashCollectionCycleDays(value);
          setCurrentCashCollectionCycleDays(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "originalCashCollectionCycleDays",
            originalCashCollectionCycleDays
          )
        }
        errorMessage={errors.originalCashCollectionCycleDays?.errorMessage}
        hasError={errors.originalCashCollectionCycleDays?.hasError}
        {...getOverrideProps(overrides, "originalCashCollectionCycleDays")}
      ></TextField>
      <CurrencyTextField
        label="What does your balance sheet report as your Total Equity?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalTotalEquity}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity: value,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity: value,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalTotalEquity ?? value;
          }
          if (errors.originalTotalEquity?.hasError) {
            runValidationTasks("originalTotalEquity", value);
          }
          setOriginalTotalEquity(value);
          setCurrentTotalEquity(value);
        }}
        onBlur={() =>
          runValidationTasks("originalTotalEquity", originalTotalEquity)
        }
        errorMessage={errors.originalTotalEquity?.errorMessage}
        hasError={errors.originalTotalEquity?.hasError}
        {...getOverrideProps(overrides, "originalTotalEquity")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your current Sellers Discretionary Earnings (SDE)?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalSde}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde: value,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde: value,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalSde ?? value;
          }
          if (errors.originalSde?.hasError) {
            runValidationTasks("originalSde", value);
          }
          setOriginalSde(value);
          setCurrentSde(value);
        }}
        onBlur={() => runValidationTasks("originalSde", originalSde)}
        errorMessage={errors.originalSde?.errorMessage}
        hasError={errors.originalSde?.hasError}
        {...getOverrideProps(overrides, "originalSde")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What was your EBITDA for the last 12 months?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalEbitda}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda: value,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda: value,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalEbitda ?? value;
          }
          if (errors.originalEbitda?.hasError) {
            runValidationTasks("originalEbitda", value);
          }
          setOriginalEbitda(value);
          setCurrentEbitda(value);

          if (industry && originalEbitda) setOriginalValuation(originalEbitda * industryEBITDAMultiples[industry]*.60);
          if (industry && currentEbitda) setCurrentValuation(originalEbitda * industryEBITDAMultiples[industry]*.60);

        }}
        onBlur={() => runValidationTasks("originalEbitda", originalEbitda)}
        errorMessage={errors.originalEbitda?.errorMessage}
        hasError={errors.originalEbitda?.hasError}
        {...getOverrideProps(overrides, "originalEbitda")}
      ></CurrencyTextField>
      <TextField
        label="Industry specific EBITDA multiple x .60"
        required={false}
        readOnly={true}
        type="number"
        step="any"
        value={originalEbitdaMultiple}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple: value,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda: value,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalEbitdaMultiple ?? value;
          }
          if (errors.originalEbitdaMultiple?.hasError) {
            runValidationTasks("originalEbitdaMultiple", value);
          }
          setOriginalEbitdaMultiple(value);
          setCurrentEbitdaMultiple(value);
        }}
        onBlur={() =>
          runValidationTasks("originalEbitdaMultiple", originalEbitdaMultiple)
        }
        errorMessage={errors.originalEbitdaMultiple?.errorMessage}
        hasError={errors.originalEbitdaMultiple?.hasError}
        {...getOverrideProps(overrides, "originalEbitdaMultiple")}
      ></TextField>
      <TextField
        label="How many W2 employees work for your company?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={originalEmployeeCount}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount: value,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount: value,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalEmployeeCount ?? value;
          }
          if (errors.originalEmployeeCount?.hasError) {
            runValidationTasks("originalEmployeeCount", value);
          }
          setOriginalEmployeeCount(value);
          setCurrentEmployeeCount(value);
        }}
        onBlur={() =>
          runValidationTasks("originalEmployeeCount", originalEmployeeCount)
        }
        errorMessage={errors.originalEmployeeCount?.errorMessage}
        hasError={errors.originalEmployeeCount?.hasError}
        {...getOverrideProps(overrides, "originalEmployeeCount")}
      ></TextField>
      <CurrencyTextField
        label="Estimated EBITDA valuation"
        required={false}
        readOnly={true}
        type="number"
        step="any"
        value={originalValuation}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation: value,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation: value,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalValuation ?? value;
          }
          if (errors.originalValuation?.hasError) {
            runValidationTasks("originalValuation", value);
          }
          setOriginalValuation(value);
          setCurrentValuation(value);
        }}
        onBlur={() =>
          runValidationTasks("originalValuation", originalValuation)
        }
        errorMessage={errors.originalValuation?.errorMessage}
        hasError={errors.originalValuation?.hasError}
        {...getOverrideProps(overrides, "originalValuation")}
      ></CurrencyTextField>
      <SwitchField
        label="Is your business owner operated?"
        defaultChecked={false}
        isDisabled={false}
        isChecked={originalOwnerOperated}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated: value,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated: value,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.originalOwnerOperated ?? value;
          }
          if (errors.originalOwnerOperated?.hasError) {
            runValidationTasks("originalOwnerOperated", value);
          }
          setOriginalOwnerOperated(value);
          setCurrentOwnerOperated(value);
        }}
        onBlur={() =>
          runValidationTasks("originalOwnerOperated", originalOwnerOperated)
        }
        errorMessage={errors.originalOwnerOperated?.errorMessage}
        hasError={errors.originalOwnerOperated?.hasError}
        {...getOverrideProps(overrides, "originalOwnerOperated")}
      ></SwitchField>
      {/* Current Fields */}
{/*       <TextField
        //label="Current total customers servered per year"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalCustomersServeredPerYear}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear: value,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalCustomersServeredPerYear ?? value;
          }
          if (errors.currentTotalCustomersServeredPerYear?.hasError) {
            runValidationTasks("currentTotalCustomersServeredPerYear", value);
          }
          setCurrentTotalCustomersServeredPerYear(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentTotalCustomersServeredPerYear",
            currentTotalCustomersServeredPerYear
          )
        }
        errorMessage={errors.currentTotalCustomersServeredPerYear?.errorMessage}
        hasError={errors.currentTotalCustomersServeredPerYear?.hasError}
        {...getOverrideProps(overrides, "currentTotalCustomersServeredPerYear")}
      ></TextField>
      <TextField
        //label="Current total transactions per year"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalTransactionsPerYear}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear: value,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalTransactionsPerYear ?? value;
          }
          if (errors.currentTotalTransactionsPerYear?.hasError) {
            runValidationTasks("currentTotalTransactionsPerYear", value);
          }
          setCurrentTotalTransactionsPerYear(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentTotalTransactionsPerYear",
            currentTotalTransactionsPerYear
          )
        }
        errorMessage={errors.currentTotalTransactionsPerYear?.errorMessage}
        hasError={errors.currentTotalTransactionsPerYear?.hasError}
        {...getOverrideProps(overrides, "currentTotalTransactionsPerYear")}
      ></TextField>
      <TextField
        //label="Current total marketing budget"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalMarketingBudget}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget: value,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalMarketingBudget ?? value;
          }
          if (errors.currentTotalMarketingBudget?.hasError) {
            runValidationTasks("currentTotalMarketingBudget", value);
          }
          setCurrentTotalMarketingBudget(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentTotalMarketingBudget",
            currentTotalMarketingBudget
          )
        }
        errorMessage={errors.currentTotalMarketingBudget?.errorMessage}
        hasError={errors.currentTotalMarketingBudget?.hasError}
        {...getOverrideProps(overrides, "currentTotalMarketingBudget")}
      ></TextField>
      <TextField
        //label="Current total sales budget"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalSalesBudget}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget: value,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalSalesBudget ?? value;
          }
          if (errors.currentTotalSalesBudget?.hasError) {
            runValidationTasks("currentTotalSalesBudget", value);
          }
          setCurrentTotalSalesBudget(value);
        }}
        onBlur={() =>
          runValidationTasks("currentTotalSalesBudget", currentTotalSalesBudget)
        }
        errorMessage={errors.currentTotalSalesBudget?.errorMessage}
        hasError={errors.currentTotalSalesBudget?.hasError}
        {...getOverrideProps(overrides, "currentTotalSalesBudget")}
      ></TextField>
      <TextField
        //label="Current total annual payroll"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalAnnualPayroll}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll: value,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalAnnualPayroll ?? value;
          }
          if (errors.currentTotalAnnualPayroll?.hasError) {
            runValidationTasks("currentTotalAnnualPayroll", value);
          }
          setCurrentTotalAnnualPayroll(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentTotalAnnualPayroll",
            currentTotalAnnualPayroll
          )
        }
        errorMessage={errors.currentTotalAnnualPayroll?.errorMessage}
        hasError={errors.currentTotalAnnualPayroll?.hasError}
        {...getOverrideProps(overrides, "currentTotalAnnualPayroll")}
      ></TextField>
      <TextField
        //label="Current annual revenue"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentAnnualRevenue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue: value,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentAnnualRevenue ?? value;
          }
          if (errors.currentAnnualRevenue?.hasError) {
            runValidationTasks("currentAnnualRevenue", value);
          }
          setCurrentAnnualRevenue(value);
        }}
        onBlur={() =>
          runValidationTasks("currentAnnualRevenue", currentAnnualRevenue)
        }
        errorMessage={errors.currentAnnualRevenue?.errorMessage}
        hasError={errors.currentAnnualRevenue?.hasError}
        {...getOverrideProps(overrides, "currentAnnualRevenue")}
      ></TextField>
      <TextField
        //label="Current net operating income"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentNetOperatingIncome}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome: value,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentNetOperatingIncome ?? value;
          }
          if (errors.currentNetOperatingIncome?.hasError) {
            runValidationTasks("currentNetOperatingIncome", value);
          }
          setCurrentNetOperatingIncome(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentNetOperatingIncome",
            currentNetOperatingIncome
          )
        }
        errorMessage={errors.currentNetOperatingIncome?.errorMessage}
        hasError={errors.currentNetOperatingIncome?.hasError}
        {...getOverrideProps(overrides, "currentNetOperatingIncome")}
      ></TextField>
      <TextField
        //label="Current gross profit margin"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentGrossProfitMargin}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin: value,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentGrossProfitMargin ?? value;
          }
          if (errors.currentGrossProfitMargin?.hasError) {
            runValidationTasks("currentGrossProfitMargin", value);
          }
          setCurrentGrossProfitMargin(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentGrossProfitMargin",
            currentGrossProfitMargin
          )
        }
        errorMessage={errors.currentGrossProfitMargin?.errorMessage}
        hasError={errors.currentGrossProfitMargin?.hasError}
        {...getOverrideProps(overrides, "currentGrossProfitMargin")}
      ></TextField>
      <TextField
        //label="Current net profit margin"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentNetProfitMargin}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin: value,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentNetProfitMargin ?? value;
          }
          if (errors.currentNetProfitMargin?.hasError) {
            runValidationTasks("currentNetProfitMargin", value);
          }
          setCurrentNetProfitMargin(value);
        }}
        onBlur={() =>
          runValidationTasks("currentNetProfitMargin", currentNetProfitMargin)
        }
        errorMessage={errors.currentNetProfitMargin?.errorMessage}
        hasError={errors.currentNetProfitMargin?.hasError}
        {...getOverrideProps(overrides, "currentNetProfitMargin")}
      ></TextField>
      <TextField
        //label="Current total assets"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalAssets}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets: value,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalAssets ?? value;
          }
          if (errors.currentTotalAssets?.hasError) {
            runValidationTasks("currentTotalAssets", value);
          }
          setCurrentTotalAssets(value);
        }}
        onBlur={() =>
          runValidationTasks("currentTotalAssets", currentTotalAssets)
        }
        errorMessage={errors.currentTotalAssets?.errorMessage}
        hasError={errors.currentTotalAssets?.hasError}
        {...getOverrideProps(overrides, "currentTotalAssets")}
      ></TextField>
      <TextField
        //label="Current cash"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentCash}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash: value,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentCash ?? value;
          }
          if (errors.currentCash?.hasError) {
            runValidationTasks("currentCash", value);
          }
          setCurrentCash(value);
        }}
        onBlur={() => runValidationTasks("currentCash", currentCash)}
        errorMessage={errors.currentCash?.errorMessage}
        hasError={errors.currentCash?.hasError}
        {...getOverrideProps(overrides, "currentCash")}
      ></TextField>
      <TextField
        //label="Current accounts receivable"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentAccountsReceivable}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable: value,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentAccountsReceivable ?? value;
          }
          if (errors.currentAccountsReceivable?.hasError) {
            runValidationTasks("currentAccountsReceivable", value);
          }
          setCurrentAccountsReceivable(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentAccountsReceivable",
            currentAccountsReceivable
          )
        }
        errorMessage={errors.currentAccountsReceivable?.errorMessage}
        hasError={errors.currentAccountsReceivable?.hasError}
        {...getOverrideProps(overrides, "currentAccountsReceivable")}
      ></TextField>
      <TextField
        //label="Current inventory"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentInventory}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory: value,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentInventory ?? value;
          }
          if (errors.currentInventory?.hasError) {
            runValidationTasks("currentInventory", value);
          }
          setCurrentInventory(value);
        }}
        onBlur={() => runValidationTasks("currentInventory", currentInventory)}
        errorMessage={errors.currentInventory?.errorMessage}
        hasError={errors.currentInventory?.hasError}
        {...getOverrideProps(overrides, "currentInventory")}
      ></TextField>
      <TextField
        //label="Current equipment and furniture"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentEquipmentAndFurniture}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture: value,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentEquipmentAndFurniture ?? value;
          }
          if (errors.currentEquipmentAndFurniture?.hasError) {
            runValidationTasks("currentEquipmentAndFurniture", value);
          }
          setCurrentEquipmentAndFurniture(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentEquipmentAndFurniture",
            currentEquipmentAndFurniture
          )
        }
        errorMessage={errors.currentEquipmentAndFurniture?.errorMessage}
        hasError={errors.currentEquipmentAndFurniture?.hasError}
        {...getOverrideProps(overrides, "currentEquipmentAndFurniture")}
      ></TextField>
      <TextField
        //label="Current real estate"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentRealEstate}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate: value,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentRealEstate ?? value;
          }
          if (errors.currentRealEstate?.hasError) {
            runValidationTasks("currentRealEstate", value);
          }
          setCurrentRealEstate(value);
        }}
        onBlur={() =>
          runValidationTasks("currentRealEstate", currentRealEstate)
        }
        errorMessage={errors.currentRealEstate?.errorMessage}
        hasError={errors.currentRealEstate?.hasError}
        {...getOverrideProps(overrides, "currentRealEstate")}
      ></TextField>
      <TextField
        //label="Current total liabilities"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalLiabilities}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities: value,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalLiabilities ?? value;
          }
          if (errors.currentTotalLiabilities?.hasError) {
            runValidationTasks("currentTotalLiabilities", value);
          }
          setCurrentTotalLiabilities(value);
        }}
        onBlur={() =>
          runValidationTasks("currentTotalLiabilities", currentTotalLiabilities)
        }
        errorMessage={errors.currentTotalLiabilities?.errorMessage}
        hasError={errors.currentTotalLiabilities?.hasError}
        {...getOverrideProps(overrides, "currentTotalLiabilities")}
      ></TextField>
      <TextField
        //label="Current total current liabilities"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalCurrentLiabilities}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities: value,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalCurrentLiabilities ?? value;
          }
          if (errors.currentTotalCurrentLiabilities?.hasError) {
            runValidationTasks("currentTotalCurrentLiabilities", value);
          }
          setCurrentTotalCurrentLiabilities(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentTotalCurrentLiabilities",
            currentTotalCurrentLiabilities
          )
        }
        errorMessage={errors.currentTotalCurrentLiabilities?.errorMessage}
        hasError={errors.currentTotalCurrentLiabilities?.hasError}
        {...getOverrideProps(overrides, "currentTotalCurrentLiabilities")}
      ></TextField>
      <TextField
        //label="Current cash collection cycle days"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentCashCollectionCycleDays}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays: value,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentCashCollectionCycleDays ?? value;
          }
          if (errors.currentCashCollectionCycleDays?.hasError) {
            runValidationTasks("currentCashCollectionCycleDays", value);
          }
          setCurrentCashCollectionCycleDays(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "currentCashCollectionCycleDays",
            currentCashCollectionCycleDays
          )
        }
        errorMessage={errors.currentCashCollectionCycleDays?.errorMessage}
        hasError={errors.currentCashCollectionCycleDays?.hasError}
        {...getOverrideProps(overrides, "currentCashCollectionCycleDays")}
      ></TextField>
      <TextField
        //label="Current total equity"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentTotalEquity}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity: value,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentTotalEquity ?? value;
          }
          if (errors.currentTotalEquity?.hasError) {
            runValidationTasks("currentTotalEquity", value);
          }
          setCurrentTotalEquity(value);
        }}
        onBlur={() =>
          runValidationTasks("currentTotalEquity", currentTotalEquity)
        }
        errorMessage={errors.currentTotalEquity?.errorMessage}
        hasError={errors.currentTotalEquity?.hasError}
        {...getOverrideProps(overrides, "currentTotalEquity")}
      ></TextField>
      <TextField
        //label="Current sde"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentSde}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde: value,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentSde ?? value;
          }
          if (errors.currentSde?.hasError) {
            runValidationTasks("currentSde", value);
          }
          setCurrentSde(value);
        }}
        onBlur={() => runValidationTasks("currentSde", currentSde)}
        errorMessage={errors.currentSde?.errorMessage}
        hasError={errors.currentSde?.hasError}
        {...getOverrideProps(overrides, "currentSde")}
      ></TextField>
      <TextField
        //label="Current ebitda"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentEbitda}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda: value,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentEbitda ?? value;
          }
          if (errors.currentEbitda?.hasError) {
            runValidationTasks("currentEbitda", value);
          }
          setCurrentEbitda(value);
        }}
        onBlur={() => runValidationTasks("currentEbitda", currentEbitda)}
        errorMessage={errors.currentEbitda?.errorMessage}
        hasError={errors.currentEbitda?.hasError}
        {...getOverrideProps(overrides, "currentEbitda")}
      ></TextField>
      <TextField
        //label="Current ebitda multiple"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentEbitdaMultiple}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple: value,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentEbitdaMultiple ?? value;
          }
          if (errors.currentEbitdaMultiple?.hasError) {
            runValidationTasks("currentEbitdaMultiple", value);
          }
          setCurrentEbitdaMultiple(value);
        }}
        onBlur={() =>
          runValidationTasks("currentEbitdaMultiple", currentEbitdaMultiple)
        }
        errorMessage={errors.currentEbitdaMultiple?.errorMessage}
        hasError={errors.currentEbitdaMultiple?.hasError}
        {...getOverrideProps(overrides, "currentEbitdaMultiple")}
      ></TextField>
      <TextField
        //label="Current employee count"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentEmployeeCount}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount: value,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentEmployeeCount ?? value;
          }
          if (errors.currentEmployeeCount?.hasError) {
            runValidationTasks("currentEmployeeCount", value);
          }
          setCurrentEmployeeCount(value);
        }}
        onBlur={() =>
          runValidationTasks("currentEmployeeCount", currentEmployeeCount)
        }
        errorMessage={errors.currentEmployeeCount?.errorMessage}
        hasError={errors.currentEmployeeCount?.hasError}
        {...getOverrideProps(overrides, "currentEmployeeCount")}
      ></TextField>
      <TextField
        //label="Current valuation"
        required={false}
        readOnly={false}
        hidden={true}
        type="number"
        step="any"
        value={currentValuation}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation: value,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentValuation ?? value;
          }
          if (errors.currentValuation?.hasError) {
            runValidationTasks("currentValuation", value);
          }
          setCurrentValuation(value);
        }}
        onBlur={() => runValidationTasks("currentValuation", currentValuation)}
        errorMessage={errors.currentValuation?.errorMessage}
        hasError={errors.currentValuation?.hasError}
        {...getOverrideProps(overrides, "currentValuation")}
      ></TextField>
      <SwitchField
        //label="Current owner operated"
        defaultChecked={false}
        isDisabled={false}
        isChecked={currentOwnerOperated}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated: value,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.currentOwnerOperated ?? value;
          }
          if (errors.currentOwnerOperated?.hasError) {
            runValidationTasks("currentOwnerOperated", value);
          }
          setCurrentOwnerOperated(value);
        }}
        onBlur={() =>
          runValidationTasks("currentOwnerOperated", currentOwnerOperated)
        }
        errorMessage={errors.currentOwnerOperated?.errorMessage}
        hasError={errors.currentOwnerOperated?.hasError}
        {...getOverrideProps(overrides, "currentOwnerOperated")}
      ></SwitchField> */}
      {/* Target Fields */}
      <TextField
        label="What is your target for the number of customers your business will serve indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalCustomersServeredPerYear}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear: value,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalCustomersServeredPerYear ?? value;
          }
          if (errors.targetTotalCustomersServeredPerYear?.hasError) {
            runValidationTasks("targetTotalCustomersServeredPerYear", value);
          }
          setTargetTotalCustomersServeredPerYear(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "targetTotalCustomersServeredPerYear",
            targetTotalCustomersServeredPerYear
          )
        }
        errorMessage={errors.targetTotalCustomersServeredPerYear?.errorMessage}
        hasError={errors.targetTotalCustomersServeredPerYear?.hasError}
        {...getOverrideProps(overrides, "targetTotalCustomersServeredPerYear")}
      ></TextField>
      <TextField
        label="What is your target for the number of customer transactions will your business will conduct indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalTransactionsPerYear}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear: value,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalTransactionsPerYear ?? value;
          }
          if (errors.targetTotalTransactionsPerYear?.hasError) {
            runValidationTasks("targetTotalTransactionsPerYear", value);
          }
          setTargetTotalTransactionsPerYear(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "targetTotalTransactionsPerYear",
            targetTotalTransactionsPerYear
          )
        }
        errorMessage={errors.targetTotalTransactionsPerYear?.errorMessage}
        hasError={errors.targetTotalTransactionsPerYear?.hasError}
        {...getOverrideProps(overrides, "targetTotalTransactionsPerYear")}
      ></TextField>
      <CurrencyTextField
        label="What is your target marketing budget you will spend indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalMarketingBudget}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget: value,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalMarketingBudget ?? value;
          }
          if (errors.targetTotalMarketingBudget?.hasError) {
            runValidationTasks("targetTotalMarketingBudget", value);
          }
          setTargetTotalMarketingBudget(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "targetTotalMarketingBudget",
            targetTotalMarketingBudget
          )
        }
        errorMessage={errors.targetTotalMarketingBudget?.errorMessage}
        hasError={errors.targetTotalMarketingBudget?.hasError}
        {...getOverrideProps(overrides, "targetTotalMarketingBudget")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target sales budget you will spend indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalSalesBudget}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget: value,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalSalesBudget ?? value;
          }
          if (errors.targetTotalSalesBudget?.hasError) {
            runValidationTasks("targetTotalSalesBudget", value);
          }
          setTargetTotalSalesBudget(value);
        }}
        onBlur={() =>
          runValidationTasks("targetTotalSalesBudget", targetTotalSalesBudget)
        }
        errorMessage={errors.targetTotalSalesBudget?.errorMessage}
        hasError={errors.targetTotalSalesBudget?.hasError}
        {...getOverrideProps(overrides, "targetTotalSalesBudget")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target annual payroll spend indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalAnnualPayroll}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll: value,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalAnnualPayroll ?? value;
          }
          if (errors.targetTotalAnnualPayroll?.hasError) {
            runValidationTasks("targetTotalAnnualPayroll", value);
          }
          setTargetTotalAnnualPayroll(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "targetTotalAnnualPayroll",
            targetTotalAnnualPayroll
          )
        }
        errorMessage={errors.targetTotalAnnualPayroll?.errorMessage}
        hasError={errors.targetTotalAnnualPayroll?.hasError}
        {...getOverrideProps(overrides, "targetTotalAnnualPayroll")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target for total annual revenue will make indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetAnnualRevenue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue: value,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetAnnualRevenue ?? value;
          }
          if (errors.targetAnnualRevenue?.hasError) {
            runValidationTasks("targetAnnualRevenue", value);
          }
          setTargetAnnualRevenue(value);
        }}
        onBlur={() =>
          runValidationTasks("targetAnnualRevenue", targetAnnualRevenue)
        }
        errorMessage={errors.targetAnnualRevenue?.errorMessage}
        hasError={errors.targetAnnualRevenue?.hasError}
        {...getOverrideProps(overrides, "targetAnnualRevenue")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target Net Operating Income you will earn indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetNetOperatingIncome}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome: value,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetNetOperatingIncome ?? value;
          }
          if (errors.targetNetOperatingIncome?.hasError) {
            runValidationTasks("targetNetOperatingIncome", value);
          }
          setTargetNetOperatingIncome(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "targetNetOperatingIncome",
            targetNetOperatingIncome
          )
        }
        errorMessage={errors.targetNetOperatingIncome?.errorMessage}
        hasError={errors.targetNetOperatingIncome?.hasError}
        {...getOverrideProps(overrides, "targetNetOperatingIncome")}
      ></CurrencyTextField>
      <TextField
        label="What is your target for Annual Gross Margin will make indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetGrossProfitMargin}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin: value,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetGrossProfitMargin ?? value;
          }
          if (errors.targetGrossProfitMargin?.hasError) {
            runValidationTasks("targetGrossProfitMargin", value);
          }
          setTargetGrossProfitMargin(value);
        }}
        onBlur={() =>
          runValidationTasks("targetGrossProfitMargin", targetGrossProfitMargin)
        }
        errorMessage={errors.targetGrossProfitMargin?.errorMessage}
        hasError={errors.targetGrossProfitMargin?.hasError}
        {...getOverrideProps(overrides, "targetGrossProfitMargin")}
      ></TextField>
      <TextField
        label="What is your target for Net Profit Margin you will earn indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetNetProfitMargin}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin: value,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetNetProfitMargin ?? value;
          }
          if (errors.targetNetProfitMargin?.hasError) {
            runValidationTasks("targetNetProfitMargin", value);
          }
          setTargetNetProfitMargin(value);
        }}
        onBlur={() =>
          runValidationTasks("targetNetProfitMargin", targetNetProfitMargin)
        }
        errorMessage={errors.targetNetProfitMargin?.errorMessage}
        hasError={errors.targetNetProfitMargin?.hasError}
        {...getOverrideProps(overrides, "targetNetProfitMargin")}
      ></TextField>
      <CurrencyTextField
        label="What is your target total assets owned indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalAssets}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets: value,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalAssets ?? value;
          }
          if (errors.targetTotalAssets?.hasError) {
            runValidationTasks("targetTotalAssets", value);
          }
          setTargetTotalAssets(value);
        }}
        onBlur={() =>
          runValidationTasks("targetTotalAssets", targetTotalAssets)
        }
        errorMessage={errors.targetTotalAssets?.errorMessage}
        hasError={errors.targetTotalAssets?.hasError}
        {...getOverrideProps(overrides, "targetTotalAssets")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target total liabilities indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalLiabilities}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities: value,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalLiabilities ?? value;
          }
          if (errors.targetTotalLiabilities?.hasError) {
            runValidationTasks("targetTotalLiabilities", value);
          }
          setTargetTotalLiabilities(value);
        }}
        onBlur={() =>
          runValidationTasks("targetTotalLiabilities", targetTotalLiabilities)
        }
        errorMessage={errors.targetTotalLiabilities?.errorMessage}
        hasError={errors.targetTotalLiabilities?.hasError}
        {...getOverrideProps(overrides, "targetTotalLiabilities")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target total current liabilities indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalCurrentLiabilities}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities: value,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalCurrentLiabilities ?? value;
          }
          if (errors.targetTotalCurrentLiabilities?.hasError) {
            runValidationTasks("targetTotalCurrentLiabilities", value);
          }
          setTargetTotalCurrentLiabilities(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "targetTotalCurrentLiabilities",
            targetTotalCurrentLiabilities
          )
        }
        errorMessage={errors.targetTotalCurrentLiabilities?.errorMessage}
        hasError={errors.targetTotalCurrentLiabilities?.hasError}
        {...getOverrideProps(overrides, "targetTotalCurrentLiabilities")}
      ></CurrencyTextField>
      <TextField
        label="What is your target Cash Collection Cycle (days) indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetCashCollectionCycleDays}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays: value,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetCashCollectionCycleDays ?? value;
          }
          if (errors.targetCashCollectionCycleDays?.hasError) {
            runValidationTasks("targetCashCollectionCycleDays", value);
          }
          setTargetCashCollectionCycleDays(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "targetCashCollectionCycleDays",
            targetCashCollectionCycleDays
          )
        }
        errorMessage={errors.targetCashCollectionCycleDays?.errorMessage}
        hasError={errors.targetCashCollectionCycleDays?.hasError}
        {...getOverrideProps(overrides, "targetCashCollectionCycleDays")}
      ></TextField>
      <CurrencyTextField
        label="What is your target total equity in your business indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetTotalEquity}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity: value,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetTotalEquity ?? value;
          }
          if (errors.targetTotalEquity?.hasError) {
            runValidationTasks("targetTotalEquity", value);
          }
          setTargetTotalEquity(value);
        }}
        onBlur={() =>
          runValidationTasks("targetTotalEquity", targetTotalEquity)
        }
        errorMessage={errors.targetTotalEquity?.errorMessage}
        hasError={errors.targetTotalEquity?.hasError}
        {...getOverrideProps(overrides, "targetTotalEquity")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target Sellers Discretionary Earnings will you use to indicate it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetSde}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde: value,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetSde ?? value;
          }
          if (errors.targetSde?.hasError) {
            runValidationTasks("targetSde", value);
          }
          setTargetSde(value);
        }}
        onBlur={() => runValidationTasks("targetSde", targetSde)}
        errorMessage={errors.targetSde?.errorMessage}
        hasError={errors.targetSde?.hasError}
        {...getOverrideProps(overrides, "targetSde")}
      ></CurrencyTextField>
      <CurrencyTextField
        label="What is your target EBITDA indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetEbitda}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda: value,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetEbitda ?? value;
          }
          if (errors.targetEbitda?.hasError) {
            runValidationTasks("targetEbitda", value);
          }
          setTargetEbitda(value);
        }}
        onBlur={() => runValidationTasks("targetEbitda", targetEbitda)}
        errorMessage={errors.targetEbitda?.errorMessage}
        hasError={errors.targetEbitda?.hasError}
        {...getOverrideProps(overrides, "targetEbitda")}
      ></CurrencyTextField>
      <TextField
        label="What is your target EBITDA Multiplier to indicate it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetEbitdaMultiple}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple: value,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetEbitdaMultiple ?? value;
          }
          if (errors.targetEbitdaMultiple?.hasError) {
            runValidationTasks("targetEbitdaMultiple", value);
          }
          setTargetEbitdaMultiple(value);
        }}
        onBlur={() =>
          runValidationTasks("targetEbitdaMultiple", targetEbitdaMultiple)
        }
        errorMessage={errors.targetEbitdaMultiple?.errorMessage}
        hasError={errors.targetEbitdaMultiple?.hasError}
        {...getOverrideProps(overrides, "targetEbitdaMultiple")}
      ></TextField>
      <TextField
        label="What is your target W2 employee headcount indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetEmployeeCount}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount: value,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetEmployeeCount ?? value;
          }
          if (errors.targetEmployeeCount?.hasError) {
            runValidationTasks("targetEmployeeCount", value);
          }
          setTargetEmployeeCount(value);
        }}
        onBlur={() =>
          runValidationTasks("targetEmployeeCount", targetEmployeeCount)
        }
        errorMessage={errors.targetEmployeeCount?.errorMessage}
        hasError={errors.targetEmployeeCount?.hasError}
        {...getOverrideProps(overrides, "targetEmployeeCount")}
      ></TextField>
      <CurrencyTextField
        label="What is your target EBITDA Multiple Valuation will serve indicating it is time to exit your business?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={targetValuation}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation: value,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetValuation ?? value;
          }
          if (errors.targetValuation?.hasError) {
            runValidationTasks("targetValuation", value);
          }
          setTargetValuation(value);
        }}
        onBlur={() => runValidationTasks("targetValuation", targetValuation)}
        errorMessage={errors.targetValuation?.errorMessage}
        hasError={errors.targetValuation?.hasError}
        {...getOverrideProps(overrides, "targetValuation")}
      ></CurrencyTextField>
      <SwitchField
        label="Do you plan to remove yourself from the businesses daily operations?"
        defaultChecked={false}
        isDisabled={false}
        isChecked={targetOwnerOperated}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated: value,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetOwnerOperated ?? value;
          }
          if (errors.targetOwnerOperated?.hasError) {
            runValidationTasks("targetOwnerOperated", value);
          }
          setTargetOwnerOperated(value);
        }}
        onBlur={() =>
          runValidationTasks("targetOwnerOperated", targetOwnerOperated)
        }
        errorMessage={errors.targetOwnerOperated?.errorMessage}
        hasError={errors.targetOwnerOperated?.hasError}
        {...getOverrideProps(overrides, "targetOwnerOperated")}
      ></SwitchField>
      <TextField
        label="What is your target Exit Date?"
        required={false}
        readOnly={false}
        type="date"
        value={targetExitDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate: value,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.targetExitDate ?? value;
          }
          if (errors.targetExitDate?.hasError) {
            runValidationTasks("targetExitDate", value);
          }
          setTargetExitDate(value);
        }}
        onBlur={() => runValidationTasks("targetExitDate", targetExitDate)}
        errorMessage={errors.targetExitDate?.errorMessage}
        hasError={errors.targetExitDate?.hasError}
        {...getOverrideProps(overrides, "targetExitDate")}
      ></TextField>
      <SelectField
        label="Please select a listing status of your business."
        placeholder="Please select an option"
        isDisabled={false}
        value={listingStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus: value,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.listingStatus ?? value;
          }
          if (errors.listingStatus?.hasError) {
            runValidationTasks("listingStatus", value);
          }
          setListingStatus(value);
        }}
        onBlur={() => runValidationTasks("listingStatus", listingStatus)}
        errorMessage={errors.listingStatus?.errorMessage}
        hasError={errors.listingStatus?.hasError}
        {...getOverrideProps(overrides, "listingStatus")}
      >
        <option
          children="Not listed"
          value="NOT_LISTED"
          {...getOverrideProps(overrides, "listingStatusoption0")}
        ></option>
        <option
          children="Listed"
          value="LISTED"
          {...getOverrideProps(overrides, "listingStatusoption1")}
        ></option>
        <option
          children="Under contract"
          value="UNDER_CONTRACT"
          {...getOverrideProps(overrides, "listingStatusoption2")}
        ></option>
        <option
          children="Sold"
          value="SOLD"
          {...getOverrideProps(overrides, "listingStatusoption3")}
        ></option>
      </SelectField>
      <TextField
        label="What percentage of shares do you own of your company?"
        required={false}
        readOnly={false}
        type="number"
        step="any"
        value={totalShares}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares: value,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.totalShares ?? value;
          }
          if (errors.totalShares?.hasError) {
            runValidationTasks("totalShares", value);
          }
          setTotalShares(value);
        }}
        onBlur={() => runValidationTasks("totalShares", totalShares)}
        errorMessage={errors.totalShares?.errorMessage}
        hasError={errors.totalShares?.hasError}
        {...getOverrideProps(overrides, "totalShares")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling: values,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.reasonsForSelling ?? values;
          }
          setReasonsForSelling(values);
          setCurrentReasonsForSellingValue("");
        }}
        currentFieldValue={currentReasonsForSellingValue}
        label={"Why are you selling your business?"}
        items={reasonsForSelling}
        hasError={errors?.reasonsForSelling?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "reasonsForSelling",
            currentReasonsForSellingValue
          )
        }
        errorMessage={errors?.reasonsForSelling?.errorMessage}
        setFieldValue={setCurrentReasonsForSellingValue}
        inputFieldRef={reasonsForSellingRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Why are you selling your business?"
          required={false}
          readOnly={false}
          value={currentReasonsForSellingValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.reasonsForSelling?.hasError) {
              runValidationTasks("reasonsForSelling", value);
            }
            setCurrentReasonsForSellingValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "reasonsForSelling",
              currentReasonsForSellingValue
            )
          }
          errorMessage={errors.reasonsForSelling?.errorMessage}
          hasError={errors.reasonsForSelling?.hasError}
          ref={reasonsForSellingRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "reasonsForSelling")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale: values,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.goalsForSale ?? values;
          }
          setGoalsForSale(values);
          setCurrentGoalsForSaleValue("");
        }}
        currentFieldValue={currentGoalsForSaleValue}
        label={"What are your goals for selling your business?"}
        items={goalsForSale}
        hasError={errors?.goalsForSale?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("goalsForSale", currentGoalsForSaleValue)
        }
        errorMessage={errors?.goalsForSale?.errorMessage}
        setFieldValue={setCurrentGoalsForSaleValue}
        inputFieldRef={goalsForSaleRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What are your goals for selling your business?"
          required={false}
          readOnly={false}
          value={currentGoalsForSaleValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.goalsForSale?.hasError) {
              runValidationTasks("goalsForSale", value);
            }
            setCurrentGoalsForSaleValue(value);
          }}
          onBlur={() =>
            runValidationTasks("goalsForSale", currentGoalsForSaleValue)
          }
          errorMessage={errors.goalsForSale?.errorMessage}
          hasError={errors.goalsForSale?.hasError}
          ref={goalsForSaleRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "goalsForSale")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision: values,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.postExitVision ?? values;
          }
          setPostExitVision(values);
          setCurrentPostExitVisionValue("");
        }}
        currentFieldValue={currentPostExitVisionValue}
        label={"What is your post-exit vision of your future?"}
        items={postExitVision}
        hasError={errors?.postExitVision?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("postExitVision", currentPostExitVisionValue)
        }
        errorMessage={errors?.postExitVision?.errorMessage}
        setFieldValue={setCurrentPostExitVisionValue}
        inputFieldRef={postExitVisionRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What is your post exit vision of your future?"
          required={false}
          readOnly={false}
          value={currentPostExitVisionValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.postExitVision?.hasError) {
              runValidationTasks("postExitVision", value);
            }
            setCurrentPostExitVisionValue(value);
          }}
          onBlur={() =>
            runValidationTasks("postExitVision", currentPostExitVisionValue)
          }
          errorMessage={errors.postExitVision?.errorMessage}
          hasError={errors.postExitVision?.hasError}
          ref={postExitVisionRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "postExitVision")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan: values,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.managementPlan ?? values;
          }
          setManagementPlan(values);
          setCurrentManagementPlanValue("");
        }}
        currentFieldValue={currentManagementPlanValue}
        label={"What is your plan for your Management team?"}
        items={managementPlan}
        hasError={errors?.managementPlan?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("managementPlan", currentManagementPlanValue)
        }
        errorMessage={errors?.managementPlan?.errorMessage}
        setFieldValue={setCurrentManagementPlanValue}
        inputFieldRef={managementPlanRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What is your plan for your Management team"
          required={false}
          readOnly={false}
          value={currentManagementPlanValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.managementPlan?.hasError) {
              runValidationTasks("managementPlan", value);
            }
            setCurrentManagementPlanValue(value);
          }}
          onBlur={() =>
            runValidationTasks("managementPlan", currentManagementPlanValue)
          }
          errorMessage={errors.managementPlan?.errorMessage}
          hasError={errors.managementPlan?.hasError}
          ref={managementPlanRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "managementPlan")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan: values,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.employeePlan ?? values;
          }
          setEmployeePlan(values);
          setCurrentEmployeePlanValue("");
        }}
        currentFieldValue={currentEmployeePlanValue}
        label={"What is your plan for your Employees?"}
        items={employeePlan}
        hasError={errors?.employeePlan?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("employeePlan", currentEmployeePlanValue)
        }
        errorMessage={errors?.employeePlan?.errorMessage}
        setFieldValue={setCurrentEmployeePlanValue}
        inputFieldRef={employeePlanRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What is your plan for your Employees?"
          required={false}
          readOnly={false}
          value={currentEmployeePlanValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.employeePlan?.hasError) {
              runValidationTasks("employeePlan", value);
            }
            setCurrentEmployeePlanValue(value);
          }}
          onBlur={() =>
            runValidationTasks("employeePlan", currentEmployeePlanValue)
          }
          errorMessage={errors.employeePlan?.errorMessage}
          hasError={errors.employeePlan?.hasError}
          ref={employeePlanRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "employeePlan")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit: values,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.whatWillHappenIfExit ?? values;
          }
          setWhatWillHappenIfExit(values);
          setCurrentWhatWillHappenIfExitValue("");
        }}
        currentFieldValue={currentWhatWillHappenIfExitValue}
        label={"What will happen if your exit is successful?"}
        items={whatWillHappenIfExit}
        hasError={errors?.whatWillHappenIfExit?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "whatWillHappenIfExit",
            currentWhatWillHappenIfExitValue
          )
        }
        errorMessage={errors?.whatWillHappenIfExit?.errorMessage}
        setFieldValue={setCurrentWhatWillHappenIfExitValue}
        inputFieldRef={whatWillHappenIfExitRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What will happen if your exit is successful?"
          required={false}
          readOnly={false}
          value={currentWhatWillHappenIfExitValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.whatWillHappenIfExit?.hasError) {
              runValidationTasks("whatWillHappenIfExit", value);
            }
            setCurrentWhatWillHappenIfExitValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "whatWillHappenIfExit",
              currentWhatWillHappenIfExitValue
            )
          }
          errorMessage={errors.whatWillHappenIfExit?.errorMessage}
          hasError={errors.whatWillHappenIfExit?.hasError}
          ref={whatWillHappenIfExitRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "whatWillHappenIfExit")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit: values,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.whatWillHappenIfNoExit ?? values;
          }
          setWhatWillHappenIfNoExit(values);
          setCurrentWhatWillHappenIfNoExitValue("");
        }}
        currentFieldValue={currentWhatWillHappenIfNoExitValue}
        label={"What will happen if your exit does not happen?"}
        items={whatWillHappenIfNoExit}
        hasError={errors?.whatWillHappenIfNoExit?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "whatWillHappenIfNoExit",
            currentWhatWillHappenIfNoExitValue
          )
        }
        errorMessage={errors?.whatWillHappenIfNoExit?.errorMessage}
        setFieldValue={setCurrentWhatWillHappenIfNoExitValue}
        inputFieldRef={whatWillHappenIfNoExitRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What will happen if your exit does not happen?"
          required={false}
          readOnly={false}
          value={currentWhatWillHappenIfNoExitValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.whatWillHappenIfNoExit?.hasError) {
              runValidationTasks("whatWillHappenIfNoExit", value);
            }
            setCurrentWhatWillHappenIfNoExitValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "whatWillHappenIfNoExit",
              currentWhatWillHappenIfNoExitValue
            )
          }
          errorMessage={errors.whatWillHappenIfNoExit?.errorMessage}
          hasError={errors.whatWillHappenIfNoExit?.hasError}
          ref={whatWillHappenIfNoExitRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "whatWillHappenIfNoExit")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit: values,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.whatWillNotHappenIfExit ?? values;
          }
          setWhatWillNotHappenIfExit(values);
          setCurrentWhatWillNotHappenIfExitValue("");
        }}
        currentFieldValue={currentWhatWillNotHappenIfExitValue}
        label={"What will not happen if you have a successful exit?"}
        items={whatWillNotHappenIfExit}
        hasError={errors?.whatWillNotHappenIfExit?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "whatWillNotHappenIfExit",
            currentWhatWillNotHappenIfExitValue
          )
        }
        errorMessage={errors?.whatWillNotHappenIfExit?.errorMessage}
        setFieldValue={setCurrentWhatWillNotHappenIfExitValue}
        inputFieldRef={whatWillNotHappenIfExitRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What will not happen if you have a successful exit?"
          required={false}
          readOnly={false}
          value={currentWhatWillNotHappenIfExitValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.whatWillNotHappenIfExit?.hasError) {
              runValidationTasks("whatWillNotHappenIfExit", value);
            }
            setCurrentWhatWillNotHappenIfExitValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "whatWillNotHappenIfExit",
              currentWhatWillNotHappenIfExitValue
            )
          }
          errorMessage={errors.whatWillNotHappenIfExit?.errorMessage}
          hasError={errors.whatWillNotHappenIfExit?.hasError}
          ref={whatWillNotHappenIfExitRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "whatWillNotHappenIfExit")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit: values,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            values = result?.whatWillNotHappenIfNoExit ?? values;
          }
          setWhatWillNotHappenIfNoExit(values);
          setCurrentWhatWillNotHappenIfNoExitValue("");
        }}
        currentFieldValue={currentWhatWillNotHappenIfNoExitValue}
        label={"What will not happen if your exit does not happen?"}
        items={whatWillNotHappenIfNoExit}
        hasError={errors?.whatWillNotHappenIfNoExit?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "whatWillNotHappenIfNoExit",
            currentWhatWillNotHappenIfNoExitValue
          )
        }
        errorMessage={errors?.whatWillNotHappenIfNoExit?.errorMessage}
        setFieldValue={setCurrentWhatWillNotHappenIfNoExitValue}
        inputFieldRef={whatWillNotHappenIfNoExitRef}
        defaultFieldValue={""}
      >
        <TextField
          label="What will not happen if your exit does not happen?"
          required={false}
          readOnly={false}
          value={currentWhatWillNotHappenIfNoExitValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.whatWillNotHappenIfNoExit?.hasError) {
              runValidationTasks("whatWillNotHappenIfNoExit", value);
            }
            setCurrentWhatWillNotHappenIfNoExitValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "whatWillNotHappenIfNoExit",
              currentWhatWillNotHappenIfNoExitValue
            )
          }
          errorMessage={errors.whatWillNotHappenIfNoExit?.errorMessage}
          hasError={errors.whatWillNotHappenIfNoExit?.hasError}
          ref={whatWillNotHappenIfNoExitRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "whatWillNotHappenIfNoExit")}
        ></TextField>
      </ArrayField>
      { /* createdAt */}
      <TextField
        //label="Created at"
        required={true}
        readOnly={false}
        hidden={true}
        type="datetime-local"
        value={createdAt && convertToLocal(new Date(createdAt))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt: value,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      { /* updatedAt */}
      <TextField
        //label="Updated at"
        required={false}
        readOnly={false}
        hidden={true}
        type="datetime-local"
        value={updatedAt && convertToLocal(new Date(updatedAt))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              businessId,
              name,
              industry,
              description,
              yearFounded,
              originalTotalCustomersServeredPerYear,
              originalTotalTransactionsPerYear,
              originalTotalMarketingBudget,
              originalTotalSalesBudget,
              originalTotalAnnualPayroll,
              originalAnnualRevenue,
              originalNetOperatingIncome,
              originalGrossProfitMargin,
              originalNetProfitMargin,
              originalTotalAssets,
              originalTotalLiabilities,
              originalTotalCurrentLiabilities,
              originalCashCollectionCycleDays,
              originalTotalEquity,
              originalSde,
              originalEbitda,
              originalEbitdaMultiple,
              originalEmployeeCount,
              originalValuation,
              originalOwnerOperated,
              currentTotalCustomersServeredPerYear,
              currentTotalTransactionsPerYear,
              currentTotalMarketingBudget,
              currentTotalSalesBudget,
              currentTotalAnnualPayroll,
              currentAnnualRevenue,
              currentNetOperatingIncome,
              currentGrossProfitMargin,
              currentNetProfitMargin,
              currentTotalAssets,
              currentCash,
              currentAccountsReceivable,
              currentInventory,
              currentEquipmentAndFurniture,
              currentRealEstate,
              currentTotalLiabilities,
              currentTotalCurrentLiabilities,
              currentCashCollectionCycleDays,
              currentTotalEquity,
              currentSde,
              currentEbitda,
              currentEbitdaMultiple,
              currentEmployeeCount,
              currentValuation,
              currentOwnerOperated,
              targetTotalCustomersServeredPerYear,
              targetTotalTransactionsPerYear,
              targetTotalMarketingBudget,
              targetTotalSalesBudget,
              targetTotalAnnualPayroll,
              targetAnnualRevenue,
              targetNetOperatingIncome,
              targetGrossProfitMargin,
              targetNetProfitMargin,
              targetTotalAssets,
              targetTotalLiabilities,
              targetTotalCurrentLiabilities,
              targetCashCollectionCycleDays,
              targetTotalEquity,
              targetSde,
              targetEbitda,
              targetEbitdaMultiple,
              targetEmployeeCount,
              targetValuation,
              targetOwnerOperated,
              targetExitDate,
              listingStatus,
              totalShares,
              reasonsForSelling,
              goalsForSale,
              postExitVision,
              managementPlan,
              employeePlan,
              whatWillHappenIfExit,
              whatWillHappenIfNoExit,
              whatWillNotHappenIfExit,
              whatWillNotHappenIfNoExit,
              createdAt,
              updatedAt: value,
            };
            const result = onChange(modelFields);
            value = result?.updatedAt ?? value;
          }
          if (errors.updatedAt?.hasError) {
            runValidationTasks("updatedAt", value);
          }
          setUpdatedAt(value);
        }}
        onBlur={() => runValidationTasks("updatedAt", updatedAt)}
        errorMessage={errors.updatedAt?.errorMessage}
        hasError={errors.updatedAt?.hasError}
        {...getOverrideProps(overrides, "updatedAt")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
