/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      businessId
      businessOwner {
        company
        createdAt
        email
        id
        linkedInProfile
        name
        owner
        planLevel
        profilePicture
        stripe_api_key
        stripe_secret
        subscriptionStatus
        updatedAt
        userId
        userType
        __typename
      }
      createdAt
      currentAccountsReceivable
      currentAnnualRevenue
      currentCash
      currentCashCollectionCycleDays
      currentEbitda
      currentEbitdaMultiple
      currentEmployeeCount
      currentEquipmentAndFurniture
      currentGrossProfitMargin
      currentInventory
      currentNetOperatingIncome
      currentNetProfitMargin
      currentOwnerOperated
      currentRealEstate
      currentSde
      currentTotalAnnualPayroll
      currentTotalAssets
      currentTotalCurrentLiabilities
      currentTotalCustomersServeredPerYear
      currentTotalEquity
      currentTotalLiabilities
      currentTotalMarketingBudget
      currentTotalSalesBudget
      currentTotalTransactionsPerYear
      currentValuation
      description
      employeePlan
      goalsForSale
      id
      industry
      listingStatus
      managementPlan
      name
      originalAnnualRevenue
      originalCashCollectionCycleDays
      originalEbitda
      originalEbitdaMultiple
      originalEmployeeCount
      originalGrossProfitMargin
      originalNetOperatingIncome
      originalNetProfitMargin
      originalOwnerOperated
      originalSde
      originalTotalAnnualPayroll
      originalTotalAssets
      originalTotalCurrentLiabilities
      originalTotalCustomersServeredPerYear
      originalTotalEquity
      originalTotalLiabilities
      originalTotalMarketingBudget
      originalTotalSalesBudget
      originalTotalTransactionsPerYear
      originalValuation
      owner
      ownerId
      postExitVision
      reasonsForSelling
      targetAnnualRevenue
      targetCashCollectionCycleDays
      targetEbitda
      targetEbitdaMultiple
      targetEmployeeCount
      targetExitDate
      targetGrossProfitMargin
      targetNetOperatingIncome
      targetNetProfitMargin
      targetOwnerOperated
      targetSde
      targetTotalAnnualPayroll
      targetTotalAssets
      targetTotalCurrentLiabilities
      targetTotalCustomersServeredPerYear
      targetTotalEquity
      targetTotalLiabilities
      targetTotalMarketingBudget
      targetTotalSalesBudget
      targetTotalTransactionsPerYear
      targetValuation
      totalShares
      updatedAt
      whatWillHappenIfExit
      whatWillHappenIfNoExit
      whatWillNotHappenIfExit
      whatWillNotHappenIfNoExit
      yearFounded
      __typename
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      content
      createdAt
      id
      isDone
      key
      updatedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      company
      createdAt
      email
      id
      linkedInProfile
      name
      ownedBusinesses {
        nextToken
        __typename
      }
      owner
      planLevel
      profilePicture
      stripe_api_key
      stripe_secret
      subscriptionStatus
      updatedAt
      userId
      userType
      __typename
    }
  }
`;
export const listBusinesses = /* GraphQL */ `
  query ListBusinesses(
    $filter: ModelBusinessFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBusinesses(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        businessId
        createdAt
        currentAccountsReceivable
        currentAnnualRevenue
        currentCash
        currentCashCollectionCycleDays
        currentEbitda
        currentEbitdaMultiple
        currentEmployeeCount
        currentEquipmentAndFurniture
        currentGrossProfitMargin
        currentInventory
        currentNetOperatingIncome
        currentNetProfitMargin
        currentOwnerOperated
        currentRealEstate
        currentSde
        currentTotalAnnualPayroll
        currentTotalAssets
        currentTotalCurrentLiabilities
        currentTotalCustomersServeredPerYear
        currentTotalEquity
        currentTotalLiabilities
        currentTotalMarketingBudget
        currentTotalSalesBudget
        currentTotalTransactionsPerYear
        currentValuation
        description
        employeePlan
        goalsForSale
        id
        industry
        listingStatus
        managementPlan
        name
        originalAnnualRevenue
        originalCashCollectionCycleDays
        originalEbitda
        originalEbitdaMultiple
        originalEmployeeCount
        originalGrossProfitMargin
        originalNetOperatingIncome
        originalNetProfitMargin
        originalOwnerOperated
        originalSde
        originalTotalAnnualPayroll
        originalTotalAssets
        originalTotalCurrentLiabilities
        originalTotalCustomersServeredPerYear
        originalTotalEquity
        originalTotalLiabilities
        originalTotalMarketingBudget
        originalTotalSalesBudget
        originalTotalTransactionsPerYear
        originalValuation
        owner
        ownerId
        postExitVision
        reasonsForSelling
        targetAnnualRevenue
        targetCashCollectionCycleDays
        targetEbitda
        targetEbitdaMultiple
        targetEmployeeCount
        targetExitDate
        targetGrossProfitMargin
        targetNetOperatingIncome
        targetNetProfitMargin
        targetOwnerOperated
        targetSde
        targetTotalAnnualPayroll
        targetTotalAssets
        targetTotalCurrentLiabilities
        targetTotalCustomersServeredPerYear
        targetTotalEquity
        targetTotalLiabilities
        targetTotalMarketingBudget
        targetTotalSalesBudget
        targetTotalTransactionsPerYear
        targetValuation
        totalShares
        updatedAt
        whatWillHappenIfExit
        whatWillHappenIfNoExit
        whatWillNotHappenIfExit
        whatWillNotHappenIfNoExit
        yearFounded
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        content
        createdAt
        id
        isDone
        key
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        company
        createdAt
        email
        id
        linkedInProfile
        name
        owner
        planLevel
        profilePicture
        stripe_api_key
        stripe_secret
        subscriptionStatus
        updatedAt
        userId
        userType
        __typename
      }
      nextToken
      __typename
    }
  }
`;
