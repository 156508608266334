// src/components/Authentication.tsx
import React, { useState } from 'react';
import { AuthState } from '../types/auth';
import SignIn from './SignIn.tsx';
import SignUp from './SignUp.tsx';
import ConfirmSignUp from './ConfirmSignUp.tsx';
import ForgotPassword from './ForgotPassword.tsx';
import ResetPassword from './ResetPassword.tsx';


interface AuthenticationProps {
    onSignIn: () => void;
  }
  
  const Authentication: React.FC<AuthenticationProps> = ({ onSignIn }) => {
    const [authState, setAuthState] = useState(AuthState.SignIn);
    const [username, setUsername] = useState('');
  
    console.log('Current username:', username);

    const handleStateChange = (newState: AuthState, newUsername: string = '') => {
        setAuthState(newState);
        setUsername(newUsername);
        console.log('Username updated:', newUsername);
      };
  
    const renderAuthComponent = () => {
      switch (authState) {
        case AuthState.SignIn:
          return <SignIn onStateChange={handleStateChange} onSignIn={onSignIn} />;
        case AuthState.SignUp:
          return <SignUp onStateChange={handleStateChange} />;
        case AuthState.ConfirmSignUp:
          return <ConfirmSignUp username={username} onStateChange={handleStateChange} />;
        case AuthState.ForgotPassword:
          return <ForgotPassword onStateChange={handleStateChange} />;
        case AuthState.ResetPassword:
          return <ResetPassword username={username} onStateChange={handleStateChange} />;
      }
    };
  
    return (
      <div className="auth-container">
        {renderAuthComponent()}
      </div>
    );
  };
  
  export default Authentication;