import React, { useState, useEffect } from 'react';
import type { Schema } from "../../amplify/data/resource";
import { Flex, View, Text, Button, Card, Badge, Heading } from '@aws-amplify/ui-react';
import { MdCheckCircle, MdWarning, MdError } from 'react-icons/md';
import { AuthUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { industryEBITDAMultiples, industryGrossProfitMargins, industryNetProfitMargins, industryRevenueMultiples, industryProfitMultiples } from "../types/NYUStearnsData";
import BusinessCreateFormWizard from './BusinessCreateFormWizard';
import { BusinessCreateFormInputValues } from '../ui-components/BusinessCreateForm';

//import { getUser, listBusinesses } from '../ui-components/graphql/queries';

//import { GraphQLResult } from '@aws-amplify/api';

// Import your Amplify-generated CRUD components here
import UserUpdateForm from '../ui-components/UserUpdateForm';
import BusinessCreateForm from '../ui-components/BusinessCreateForm';
import BusinessUpdateForm from '../ui-components/BusinessUpdateForm';
// import CertificationCreateForm from '../ui-components/CertificationCreateForm';
// import EducationCreateForm from '../ui-components/EducationCreateForm';
// import DocumentCreateForm from '../ui-components/DocumentCreateForm';
// import FinancialsCreateForm from '../ui-components/FinancialsCreateForm';
// import FinancialsUpdateForm from '../ui-components/FinancialsUpdateForm';
// import FinancialForecastCreateForm from '../ui-components/FinancialForecastCreateForm';
// import ExitPlanCreateForm from '../ui-components/ExitPlanCreateForm';
// import ExitPreparationTeamMemberCreateForm from '../ui-components/ExitPreparationTeamMemberCreateForm';
// import SaleMemorandumCreateForm from '../ui-components/SaleMemorandumCreateForm';

const client = generateClient<Schema>();
const HEADER_HEIGHT = 64;
const FOOTER_HEIGHT = 64;

interface DashboardProps {
  user: AuthUser | undefined;
  signOut: (() => void) | undefined;
}

const TypedBusinessCreateForm = BusinessCreateForm as React.ComponentType<{
  user: Schema['User']['type'] | null;
  onSuccess?: () => void;
  // Add other props as needed
}>;

const Dashboard: React.FC<DashboardProps> = ({ user, signOut }) => {
  const [activeComponent, setActiveComponent] = useState<string | null>(null);
  const [userType, setUserType] = useState<string | null>(null);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<Schema["User"]["type"] | null>(null);
  const [ownedBusinesses, setOwnedBusinesses] = useState<Schema["Business"]["type"][] | null>(null);

  useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user]);

  // const fetchUserData = async () => {
  //   try {
  //     if (!user?.userId) {
  //       console.error('User ID is undefined');
  //       return;
  //     }
  //     const userData = await client.models.User.get({ id: user.userId });
  //     if (userData && userData.data) {
  //       setCurrentUser(userData.data);
  //       if (currentUser) {
  //         console.log('currentUser: ', { currentUser });
  //       }
  //       if (currentUser && currentUser.userType) {
  //         setUserType(currentUser.userType);
  //       }
  //       // Fetch owned businesses separately
  //       if (currentUser && currentUser.id && currentUser.userType && currentUser.userType == 'BUSINESS_OWNER') {
  //         const ownedBusinessesResponse = await client.models.Business.list({  filter: { ownerId: { eq: currentUser.id } }  });
  //         setOwnedBusinesses(ownedBusinessesResponse.data);
  //       }
  //     } else {
  //       console.error("Failed to get userData");
  //     }
  //   } catch (error) {
  //     console.error('Error fetching user data:', error);
  //   }
  // };

  const fetchUserData = async () => {
    console.log('Entering fetchUserData function');
    try {
      if (!user?.userId) {
        console.error('User ID is undefined');
        return;
      }
      console.log('Fetching user data for userId:', user.userId);
  
      const userData = await client.models.User.get({ id: user.userId });
      console.log('User data received:', userData);
  
      if (userData && userData.data) {
        console.log('Setting currentUser with data:', userData.data);
        setCurrentUser(userData.data);
  
        console.log('Current user after setting:', currentUser);
  
        if (userData.data.userType) {
          console.log('Setting userType:', userData.data.userType);
          setUserType(userData.data.userType);
        } else {
          console.log('userType not found in userData');
        }
  
        // Fetch owned businesses separately
        if (userData.data.id && userData.data.userType === 'BUSINESS_OWNER') {
          console.log('Fetching owned businesses for user:', userData.data.id);
          const ownedBusinessesResponse = await client.models.Business.list({ 
            filter: { ownerId: { eq: userData.data.id } } 
          });
          console.log('Owned businesses response:', ownedBusinessesResponse);
  
          if (ownedBusinessesResponse.data) {
            console.log('Setting ownedBusinesses:', ownedBusinessesResponse.data);
            setOwnedBusinesses(ownedBusinessesResponse.data);
          } else {
            console.log('No owned businesses data in response');
          }
        } else {
          console.log('Not fetching owned businesses. User ID:', userData.data.id, 'User Type:', userData.data.userType);
        }
      } else {
        console.error("Failed to get userData");
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
    console.log('Exiting fetchUserData function');
  };

  const getExitReadiness = (business: Schema["Business"]["type"]) => {
    let score = 0;
    // Annual Revenue Tests
    if (business.currentAnnualRevenue && business.currentAnnualRevenue > 0) score++;
    if (business.currentAnnualRevenue && business.originalAnnualRevenue && business.currentAnnualRevenue > business.originalAnnualRevenue) score++;
    if (business.currentAnnualRevenue && business.targetAnnualRevenue && business.currentAnnualRevenue >= business.targetAnnualRevenue) score++;

    // Net Operating Income Tests
    if (business.currentNetOperatingIncome && business.currentNetOperatingIncome > 0) score++;
    if (business.currentNetOperatingIncome && business.originalNetOperatingIncome && business.currentNetOperatingIncome > business.originalNetOperatingIncome) score++;
    if (business.currentNetOperatingIncome && business.targetNetOperatingIncome && business.currentNetOperatingIncome > business.targetNetOperatingIncome) score++;

    // Gross Profit Margin Tests
    if (business.currentGrossProfitMargin && business.originalGrossProfitMargin && business.currentGrossProfitMargin >= business.originalGrossProfitMargin) score++;
    if (business.currentGrossProfitMargin && business.industry && business.currentGrossProfitMargin >= industryGrossProfitMargins[business.industry]) score++;
    if (business.currentGrossProfitMargin && business.targetGrossProfitMargin && business.currentGrossProfitMargin >= business.targetGrossProfitMargin) score++;

    // Net Profit Margin Tests
    if (business.currentNetProfitMargin && business.originalNetProfitMargin && business.currentNetProfitMargin >= business.originalNetProfitMargin) score++;
    if (business.currentNetProfitMargin && business.industry && business.currentNetProfitMargin >= industryNetProfitMargins[business.industry]) score++;
    if (business.currentNetProfitMargin && business.targetNetProfitMargin && business.currentNetProfitMargin >= business.targetNetProfitMargin) score++;

    // EBITDA Tests
    if (business.currentEbitda && business.currentEbitda > 0) score++;
    if (business.currentEbitda && business.originalEbitda && business.currentEbitda > business.originalEbitda) score++;
    if (business.currentEbitda && business.targetEbitda && business.currentEbitda > business.targetEbitda) score++;

    // EBITDA Multiple Tests
    if (business.currentEbitdaMultiple && business.currentEbitdaMultiple > 0) score++;
    if (business.currentEbitdaMultiple && business.originalEbitdaMultiple && business.currentEbitdaMultiple > business.originalEbitdaMultiple) score++;
    if (business.currentEbitdaMultiple && business.targetEbitdaMultiple && business.currentEbitdaMultiple > business.targetEbitdaMultiple) score++;
    if (business.currentEbitdaMultiple && business.industry && business.currentEbitdaMultiple > industryEBITDAMultiples[business.industry]) score++;

    // Employee Count Tests
    if (business.currentEmployeeCount && business.currentEmployeeCount > 0) score++;
    if (business.currentEmployeeCount && business.originalEmployeeCount && business.currentEmployeeCount > business.originalEmployeeCount) score++;
    if (business.currentEmployeeCount && business.targetEmployeeCount && business.currentEmployeeCount >= business.targetEmployeeCount) score++;

    // Valuation Tests
    if (business.currentValuation && business.currentValuation > 0) score++;
    if (business.currentValuation && business.originalValuation && business.currentValuation > business.originalValuation) score++;
    if (business.currentValuation && business.targetValuation && business.currentValuation >= business.targetValuation) score++;

    // Self Operated Tests
    if (business.currentOwnerOperated === false) score++;
    if (business.targetOwnerOperated === false) score++;

    // Exit Team in Place?

    // Due Dilligence Documenation in Place?

    // Subjective Tests
    if (business.reasonsForSelling && business.reasonsForSelling.length > 0) score++
    if (business.reasonsForSelling && business.reasonsForSelling.length >= 3) score++
    if (business.goalsForSale && business.goalsForSale.length > 0) score++
    if (business.goalsForSale && business.goalsForSale.length >= 3) score++
    if (business.postExitVision && business.postExitVision.length > 0) score++
    if (business.postExitVision && business.postExitVision.length >= 3) score++
    if (business.managementPlan && business.managementPlan.length > 0) score++
    if (business.managementPlan && business.managementPlan.length >= 3) score++
    if (business.employeePlan && business.employeePlan.length > 0) score++
    if (business.employeePlan && business.employeePlan.length >= 3) score++
    if (business.whatWillHappenIfExit && business.whatWillHappenIfExit.length > 0) score++
    if (business.whatWillHappenIfExit && business.whatWillHappenIfExit.length >= 3) score++
    if (business.whatWillHappenIfNoExit && business.whatWillHappenIfNoExit.length > 0) score++
    if (business.whatWillHappenIfNoExit && business.whatWillHappenIfNoExit.length >= 3) score++
    if (business.whatWillNotHappenIfExit && business.whatWillNotHappenIfExit.length > 0) score++
    if (business.whatWillNotHappenIfExit && business.whatWillNotHappenIfExit.length >= 3) score++
    if (business.whatWillNotHappenIfNoExit && business.whatWillNotHappenIfNoExit.length > 0) score++
    if (business.whatWillNotHappenIfNoExit && business.whatWillNotHappenIfNoExit.length >= 3) score++

    // Add more conditions as needed

    if (score >= 40) return { icon: <MdCheckCircle color="green" />, text: "Ready" };
    if (score >= 20) return { icon: <MdWarning color="orange" />, text: "In Progress" };
    return { icon: <MdError color="red" />, text: "Not Ready" };
  };


  const calculateEbitdaValuation = (business: Schema["Business"]["type"]): string => {
    if (business.currentEbitda != null && business.currentEbitdaMultiple != null) {
      const valuation = business.currentEbitda * business.currentEbitdaMultiple;
      return `$${valuation.toLocaleString()}`;
    }
    return 'N/A';
  };

  const calculateBookValue = (business: Schema["Business"]["type"]): string => {
    if (business.currentTotalAssets != null && business.currentTotalLiabilities != null) {
      const valuation = business.currentTotalAssets - business.currentTotalLiabilities;
      `$${valuation.toLocaleString()}`;
    }
    return 'N/A';
  }

  const calculateRevenueValuation = (business: Schema["Business"]["type"]): string => {
    if (business.currentAnnualRevenue != null && business.industry != null) {
      const revenueMultiple = industryRevenueMultiples[business.industry] || 2.57; // Use total market average if industry not found
      const valuation = business.currentAnnualRevenue * revenueMultiple;
      return `$${valuation.toLocaleString()}`;
    }
    return 'N/A';
  };

  const calculateNetProfitValuation = (business: Schema["Business"]["type"]): string => {
    if (business.currentNetOperatingIncome != null && business.industry != null) {
      // Get the industry-specific profit multiple, or use the market average if not found
      const profitMultiple = industryProfitMultiples[business.industry] || 22.64;

      // Apply a discount for private companies (using 60% of public multiple)
      const privateCompanyMultiple = profitMultiple * 0.60;

      const valuation = business.currentNetOperatingIncome * privateCompanyMultiple;
      return `$${valuation.toLocaleString()}`;
    }
    return 'N/A';
  };

  const calculateLiquidatedValuation = (business: Schema["Business"]["type"]): string => {
    if (business.currentTotalAssets == null) {
      return 'N/A';
    }

    let liquidationValue = 0;

    // Cash is typically worth 100% of its value
    liquidationValue += business.currentCash || 0;

    // Accounts Receivable might be worth 70-80% of face value
    liquidationValue += (business.currentAccountsReceivable || 0) * 0.75;

    // Inventory might be worth 50% of book value
    liquidationValue += (business.currentInventory || 0) * 0.5;

    // Equipment and Furniture might be worth 20-50% of book value
    liquidationValue += (business.currentEquipmentAndFurniture || 0) * 0.35;

    // Real Estate might be worth 70-90% of appraised value
    liquidationValue += (business.currentRealEstate || 0) * 0.8;

    // Subtract liabilities
    liquidationValue -= business.currentTotalLiabilities || 0;

    return `$${Math.max(0, liquidationValue).toLocaleString()}`;
  };


  const renderComponent = () => {
    console.log('renderComponent function called. Active component:', activeComponent);
    switch (activeComponent) {
      case 'createBusiessWizard':
      return <BusinessCreateFormWizard
        user={currentUser}
        onSubmit={(fields: BusinessCreateFormInputValues) => {
          // Here you would handle the form submission
          console.log('Form submitted with fields:', fields);
          // Call your API to create the business
          // Then update your state and UI as needed
        }}
        onSuccess={() => { 
          setActiveComponent(null); 
          fetchUserData(); 
        }}
      />;
      case 'createBusiness':
        return <TypedBusinessCreateForm 
          user={currentUser} 
          onSuccess={() => { 
            setActiveComponent(null); 
            fetchUserData(); 
          }} 
        />;
      case 'updateBusiness':
        return selectedBusinessId ? (
          <BusinessUpdateForm
            id={selectedBusinessId}
            onSuccess={() => { setActiveComponent(null); setSelectedBusinessId(null); fetchUserData(); }}
          />
        ) : null;
      // case 'uploadDocument':
      //   return <DocumentCreateForm />;
      // case 'createFinancials':
      //   return <FinancialsCreateForm />;
      // case 'updateFinancials':
      //   return <FinancialsUpdateForm />;
      // case 'createForecast':
      //   return <FinancialForecastCreateForm />;
      // case 'createExitProfessionals':
      //   return <ExitPreparationTeamMemberCreateForm />;
      // case 'createExitPlan':
      //   return <ExitPlanCreateForm />;
      // case 'createSalesMemorandum':
      //   return <SaleMemorandumCreateForm />;
      // case 'createEducation':
      //   return <EducationCreateForm />;
      // case 'createCertifications':
      //   return <CertificationCreateForm />;
      case 'profile':
        return <UserUpdateForm id={user?.userId} onSuccess={() => { setActiveComponent(null); fetchUserData(); }} />;
      case 'businessList':
        console.log('Rendering business list');
        return (
          <View>
            <Flex justifyContent="space-between" alignItems="center" marginBottom="1rem">
              <Heading level={4}>Your Businesses</Heading>
              <Button onClick={() => setActiveComponent('createBusiness')}>Add Business</Button>
            </Flex>
            <Flex direction="row" wrap="wrap" gap="1rem">
              {ownedBusinesses?.map((business) => {
                const readiness = getExitReadiness(business);
                return (
                  <Card key={business.id} variation="elevated" width="calc(50% - 0.5rem)">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading level={5}>{business.name}</Heading>
                      <Badge variation="info">
                        <Flex alignItems="center" gap="0.5rem">
                          {readiness.icon}
                          <Text>{readiness.text}</Text>
                        </Flex>
                      </Badge>
                    </Flex>
                    <Text>Industry: {business.industry}</Text>
                    <Text>Annual Revenue: ${business.currentAnnualRevenue?.toLocaleString() || 'N/A'}</Text>
                    <Text>EBITDA: ${business.currentEbitda?.toLocaleString() || 'N/A'}</Text>
                    <Text>Valuations:</Text>
                    <Text marginLeft="relative.small">EBITDA Multiple Valuation Method: {calculateEbitdaValuation(business)}</Text>
                    <Text marginLeft="relative.small">Revenue Multiple Valuation Method: {calculateRevenueValuation(business)}</Text>
                    <Text marginLeft="relative.small">Profit Valuation Method: {calculateNetProfitValuation(business)}</Text>
                    <Text marginLeft="relative.small">Book Value Method: {calculateBookValue(business)}</Text>
                    <Text marginLeft="relative.small">Liquidation Value: {calculateLiquidatedValuation(business)}</Text>
                    <Button onClick={() => { setSelectedBusinessId(business.id); setActiveComponent('updateBusiness'); }}>
                      Update Business
                    </Button>
                  </Card>
                );
              })}
            </Flex>
          </View>
        );
      default:
        console.log('Rendering default welcome message');
        return <Text>Welcome to The Better Exit Dashboard</Text>;
    }
  };

  const renderMenu = () => {
    console.log('renderMenu function called. User type:', userType);
    const menuItems = [
      { label: 'My Profile', action: () => setActiveComponent('profile') },
      { label: 'Sign Out', action: signOut },
    ];

    if (userType === 'BUSINESS_OWNER') {
      menuItems.push(
        { label: 'My Businesses', action: () => setActiveComponent('businessList') },
        { label: 'Upload Document', action: () => setActiveComponent('uploadDocument') },
        { label: 'Create Financial Analysis', action: () => setActiveComponent('createFinancials') },
        { label: 'Create Financial Projection', action: () => setActiveComponent('createForecast') },
        { label: 'Assign Exit Team Member', action: () => setActiveComponent('createExitProfessionals') },
        { label: 'Create Exit Plan', action: () => setActiveComponent('createExitPlan') },
        { label: 'Create Sales Memorandum', action: () => setActiveComponent('createSalesMemorandum') }
      );
    }

    if (userType === 'INVESTOR_ENTREPRENEUR') {
      menuItems.push(
        { label: 'Businesses For Sale', action: () => setActiveComponent('forSaleBusinesses') },
        { label: 'My Investments', action: () => setActiveComponent('listBusinesses') }
      )
    }

    if (userType === 'EXIT_PROFESSIONAL') {
      menuItems.push(
        { label: 'Businesses Seeking Exit Pros', action: () => setActiveComponent('listBusinesses') },
        { label: 'Add Education', action: () => setActiveComponent('createEducation') },
        { label: 'Add Certification', action: () => setActiveComponent('createCertifications') }
      )
    }

    if (userType === 'JOB_SEEKER') {
      menuItems.push(
        { label: 'Search Job Opportunities', action: () => setActiveComponent('jobOpportunities') },
        { label: 'Add Education', action: () => setActiveComponent('createEducation') },
        { label: 'Add Certification', action: () => setActiveComponent('createCertifications') }
      )
    }

    return (
      <Flex direction="column" gap="0.5rem">
        {menuItems.map((item, index) => (
          <Button
            key={index}
            onClick={item.action}
            variation="primary"
            backgroundColor="var(--charcoal)"
            color="white"
            justifyContent="flex-start"
            width="100%"
          >
            {item.label}
          </Button>
        ))}
      </Flex>
    );
  };



  console.log('Rendering Dashboard component');
  return (
    <Flex direction="row" height="100vh">
      <View
        width="250px"
        padding="1rem"
        backgroundColor="var(--amplify-colors-background-secondary)"
      >
        <Text fontSize="1.5rem" fontWeight="bold" marginBottom="1rem">
          The Better Exit
        </Text>
        {renderMenu()}
      </View>
      <View
        flex={1}
        padding="1rem"
        marginTop={`${HEADER_HEIGHT}px`}
        marginBottom={`${FOOTER_HEIGHT}px`}
        overflow="auto"
      >
        {renderComponent()}
      </View>
    </Flex>
  );
};

export default Dashboard;