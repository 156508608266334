type IndustryData = {
    [industry: string]: number;
  };
  
  export const industryOptions = [
    { value: "Advertising", label: "Advertising" },
    { value: "Aerospace/Defense", label: "Aerospace/Defense" },
    { value: "Air Transport", label: "Air Transport" },
    { value: "Apparel", label: "Apparel" },
    { value: "Auto & Truck", label: "Auto & Truck" },
    { value: "Auto Parts", label: "Auto Parts" },
    { value: "Bank (Money Center)", label: "Bank (Money Center)" },
    { value: "Banks (Regional)", label: "Banks (Regional)" },
    { value: "Beverage (Alcoholic)", label: "Beverage (Alcoholic)" },
    { value: "Beverage (Soft)", label: "Beverage (Soft)" },
    { value: "Broadcasting", label: "Broadcasting" },
    { value: "Brokerage & Investment Banking", label: "Brokerage & Investment Banking" },
    { value: "Building Materials", label: "Building Materials" },
    { value: "Business & Consumer Services", label: "Business & Consumer Services" },
    { value: "Cable TV", label: "Cable TV" },
    { value: "Chemical (Basic)", label: "Chemical (Basic)" },
    { value: "Chemical (Diversified)", label: "Chemical (Diversified)" },
    { value: "Chemical (Specialty)", label: "Chemical (Specialty)" },
    { value: "Coal & Related Energy", label: "Coal & Related Energy" },
    { value: "Computer Services", label: "Computer Services" },
    { value: "Computers/Peripherals", label: "Computers/Peripherals" },
    { value: "Construction Supplies", label: "Construction Supplies" },
    { value: "Diversified", label: "Diversified" },
    { value: "Drugs (Biotechnology)", label: "Drugs (Biotechnology)" },
    { value: "Drugs (Pharmaceutical)", label: "Drugs (Pharmaceutical)" },
    { value: "Education", label: "Education" },
    { value: "Electrical Equipment", label: "Electrical Equipment" },
    { value: "Electronics (Consumer & Office)", label: "Electronics (Consumer & Office)" },
    { value: "Electronics (General)", label: "Electronics (General)" },
    { value: "Engineering/Construction", label: "Engineering/Construction" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Environmental & Waste Services", label: "Environmental & Waste Services" },
    { value: "Farming/Agriculture", label: "Farming/Agriculture" },
    { value: "Financial Svcs. (Non-bank & Insurance)", label: "Financial Svcs. (Non-bank & Insurance)" },
    { value: "Food Processing", label: "Food Processing" },
    { value: "Food Wholesalers", label: "Food Wholesalers" },
    { value: "Furn/Home Furnishings", label: "Furn/Home Furnishings" },
    { value: "Green & Renewable Energy", label: "Green & Renewable Energy" },
    { value: "Healthcare Products", label: "Healthcare Products" },
    { value: "Healthcare Support Services", label: "Healthcare Support Services" },
    { value: "Heathcare Information and Technology", label: "Heathcare Information and Technology" },
    { value: "Homebuilding", label: "Homebuilding" },
    { value: "Hospitals/Healthcare Facilities", label: "Hospitals/Healthcare Facilities" },
    { value: "Hotel/Gaming", label: "Hotel/Gaming" },
    { value: "Household Products", label: "Household Products" },
    { value: "Information Services", label: "Information Services" },
    { value: "Insurance (General)", label: "Insurance (General)" },
    { value: "Insurance (Life)", label: "Insurance (Life)" },
    { value: "Insurance (Prop/Cas.)", label: "Insurance (Prop/Cas.)" },
    { value: "Investments & Asset Management", label: "Investments & Asset Management" },
    { value: "Machinery", label: "Machinery" },
    { value: "Metals & Mining", label: "Metals & Mining" },
    { value: "Office Equipment & Services", label: "Office Equipment & Services" },
    { value: "Oil/Gas (Integrated)", label: "Oil/Gas (Integrated)" },
    { value: "Oil/Gas (Production and Exploration)", label: "Oil/Gas (Production and Exploration)" },
    { value: "Oil/Gas Distribution", label: "Oil/Gas Distribution" },
    { value: "Oilfield Svcs/Equip.", label: "Oilfield Svcs/Equip." },
    { value: "Packaging & Container", label: "Packaging & Container" },
    { value: "Paper/Forest Products", label: "Paper/Forest Products" },
    { value: "Power", label: "Power" },
    { value: "Precious Metals", label: "Precious Metals" },
    { value: "Publishing & Newspapers", label: "Publishing & Newspapers" },
    { value: "R.E.I.T.", label: "R.E.I.T." },
    { value: "Real Estate (Development)", label: "Real Estate (Development)" },
    { value: "Real Estate (General/Diversified)", label: "Real Estate (General/Diversified)" },
    { value: "Real Estate (Operations & Services)", label: "Real Estate (Operations & Services)" },
    { value: "Recreation", label: "Recreation" },
    { value: "Reinsurance", label: "Reinsurance" },
    { value: "Restaurant/Dining", label: "Restaurant/Dining" },
    { value: "Retail (Automotive)", label: "Retail (Automotive)" },
    { value: "Retail (Building Supply)", label: "Retail (Building Supply)" },
    { value: "Retail (Distributors)", label: "Retail (Distributors)" },
    { value: "Retail (General)", label: "Retail (General)" },
    { value: "Retail (Grocery and Food)", label: "Retail (Grocery and Food)" },
    { value: "Retail (Online)", label: "Retail (Online)" },
    { value: "Retail (Special Lines)", label: "Retail (Special Lines)" },
    { value: "Rubber& Tires", label: "Rubber& Tires" },
    { value: "Semiconductor", label: "Semiconductor" },
    { value: "Semiconductor Equip", label: "Semiconductor Equip" },
    { value: "Shipbuilding & Marine", label: "Shipbuilding & Marine" },
    { value: "Shoe", label: "Shoe" },
    { value: "Software (Entertainment)", label: "Software (Entertainment)" },
    { value: "Software (Internet)", label: "Software (Internet)" },
    { value: "Software (System & Application)", label: "Software (System & Application)" },
    { value: "Steel", label: "Steel" },
    { value: "Telecom (Wireless)", label: "Telecom (Wireless)" },
    { value: "Telecom. Equipment", label: "Telecom. Equipment" },
    { value: "Telecom. Services", label: "Telecom. Services" },
    { value: "Tobacco", label: "Tobacco" },
    { value: "Transportation", label: "Transportation" },
    { value: "Transportation (Railroads)", label: "Transportation (Railroads)" },
    { value: "Trucking", label: "Trucking" },
    { value: "Utility (General)", label: "Utility (General)" },
    { value: "Utility (Water)", label: "Utility (Water)" },
  ];
  
  export const industryEBITDAMultiples: IndustryData = {
    "Advertising": 11.56,
    "Aerospace/Defense": 13.89,
    "Air Transport": 9.87,
    "Apparel": 11.98,
    "Auto & Truck": 10.11,
    "Auto Parts": 9.85,
    "Bank (Money Center)": 9.69,
    "Banks (Regional)": 10.46,
    "Beverage (Alcoholic)": 15.53,
    "Beverage (Soft)": 19.05,
    "Broadcasting": 9.26,
    "Brokerage & Investment Banking": 14.29,
    "Building Materials": 11.96,
    "Business & Consumer Services": 12.33,
    "Cable TV": 9.31,
    "Chemical (Basic)": 9.39,
    "Chemical (Diversified)": 11.68,
    "Chemical (Specialty)": 13.68,
    "Coal & Related Energy": 7.59,
    "Computer Services": 14.75,
    "Computers/Peripherals": 13.15,
    "Construction Supplies": 11.56,
    "Diversified": 9.89,
    "Drugs (Biotechnology)": 14.44,
    "Drugs (Pharmaceutical)": 13.14,
    "Education": 11.85,
    "Electrical Equipment": 14.15,
    "Electronics (Consumer & Office)": 9.71,
    "Electronics (General)": 14.24,
    "Engineering/Construction": 9.65,
    "Entertainment": 12.12,
    "Environmental & Waste Services": 11.81,
    "Farming/Agriculture": 12.06,
    "Financial Svcs. (Non-bank & Insurance)": 13.11,
    "Food Processing": 12.84,
    "Food Wholesalers": 11.71,
    "Furn/Home Furnishings": 11.76,
    "Green & Renewable Energy": 13.27,
    "Healthcare Products": 14.46,
    "Healthcare Support Services": 11.84,
    "Heathcare Information and Technology": 15.69,
    "Homebuilding": 10.21,
    "Hospitals/Healthcare Facilities": 10.05,
    "Hotel/Gaming": 11.24,
    "Household Products": 14.15,
    "Information Services": 14.59,
    "Insurance (General)": 11.44,
    "Insurance (Life)": 10.17,
    "Insurance (Prop/Cas.)": 11.77,
    "Investments & Asset Management": 11.48,
    "Machinery": 12.51,
    "Metals & Mining": 9.72,
    "Office Equipment & Services": 11.34,
    "Oil/Gas (Integrated)": 7.51,
    "Oil/Gas (Production and Exploration)": 7.88,
    "Oil/Gas Distribution": 10.41,
    "Oilfield Svcs/Equip.": 10.54,
    "Packaging & Container": 11.05,
    "Paper/Forest Products": 9.54,
    "Power": 9.8,
    "Precious Metals": 11.04,
    "Publishing & Newspapers": 8.06,
    "R.E.I.T.": 17.09,
    "Real Estate (Development)": 14.42,
    "Real Estate (General/Diversified)": 14.67,
    "Real Estate (Operations & Services)": 16.34,
    "Recreation": 10.58,
    "Reinsurance": 11.11,
    "Restaurant/Dining": 11.79,
    "Retail (Automotive)": 9.4,
    "Retail (Building Supply)": 12.21,
    "Retail (Distributors)": 10.35,
    "Retail (General)": 9.41,
    "Retail (Grocery and Food)": 9.69,
    "Retail (Online)": 14.31,
    "Retail (Special Lines)": 10.49,
    "Rubber& Tires": 8.13,
    "Semiconductor": 15.48,
    "Semiconductor Equip": 15.4,
    "Shipbuilding & Marine": 9.68,
    "Shoe": 13.26,
    "Software (Entertainment)": 16.11,
    "Software (Internet)": 15.53,
    "Software (System & Application)": 17.33,
    "Steel": 8.28,
    "Telecom (Wireless)": 7.93,
    "Telecom. Equipment": 14.13,
    "Telecom. Services": 7.91,
    "Tobacco": 11.05,
    "Transportation": 11.6,
    "Transportation (Railroads)": 12.5,
    "Trucking": 11.32,
    "Utility (General)": 10.97,
    "Utility (Water)": 13.31,
  };

  export const industryGrossProfitMargins: IndustryData = {
    "Advertising": 0.4744,
    "Aerospace/Defense": 0.2149,
    "Air Transport": 0.2592,
    "Apparel": 0.4289,
    "Auto & Truck": 0.1804,
    "Auto Parts": 0.2372,
    "Bank (Money Center)": 0.7660,
    "Banks (Regional)": 0.7660,
    "Beverage (Alcoholic)": 0.5453,
    "Beverage (Soft)": 0.5674,
    "Broadcasting": 0.5953,
    "Brokerage & Investment Banking": 0.9009,
    "Building Materials": 0.3097,
    "Business & Consumer Services": 0.4041,
    "Cable TV": 0.5953,
    "Chemical (Basic)": 0.2597,
    "Chemical (Diversified)": 0.2985,
    "Chemical (Specialty)": 0.3489,
    "Coal & Related Energy": 0.2676,
    "Computer Services": 0.3283,
    "Computers/Peripherals": 0.4339,
    "Construction Supplies": 0.3261,
    "Diversified": 0.3261,
    "Drugs (Biotechnology)": 0.7839,
    "Drugs (Pharmaceutical)": 0.7274,
    "Education": 0.5588,
    "Electrical Equipment": 0.3196,
    "Electronics (Consumer & Office)": 0.4339,
    "Electronics (General)": 0.3582,
    "Engineering/Construction": 0.1694,
    "Entertainment": 0.5953,
    "Environmental & Waste Services": 0.3775,
    "Farming/Agriculture": 0.2676,
    "Financial Svcs. (Non-bank & Insurance)": 0.7660,
    "Food Processing": 0.3023,
    "Food Wholesalers": 0.1560,
    "Furn/Home Furnishings": 0.4289,
    "Green & Renewable Energy": 0.4305,
    "Healthcare Products": 0.5653,
    "Healthcare Support Services": 0.3775,
    "Heathcare Information and Technology": 0.5653,
    "Homebuilding": 0.2149,
    "Hospitals/Healthcare Facilities": 0.5653,
    "Hotel/Gaming": 0.5953,
    "Household Products": 0.4289,
    "Information Services": 0.6897,
    "Insurance (General)": 0.7660,
    "Insurance (Life)": 0.7660,
    "Insurance (Prop/Cas.)": 0.7660,
    "Investments & Asset Management": 0.7660,
    "Machinery": 0.3261,
    "Metals & Mining": 0.2676,
    "Office Equipment & Services": 0.4339,
    "Oil/Gas (Integrated)": 0.2676,
    "Oil/Gas (Production and Exploration)": 0.2676,
    "Oil/Gas Distribution": 0.2676,
    "Oilfield Svcs/Equip.": 0.2676,
    "Packaging & Container": 0.2372,
    "Paper/Forest Products": 0.2676,
    "Power": 0.4305,
    "Precious Metals": 0.2676,
    "Publishing & Newspapers": 0.5953,
    "R.E.I.T.": 0.6712,
    "Real Estate (Development)": 0.3261,
    "Real Estate (General/Diversified)": 0.3261,
    "Real Estate (Operations & Services)": 0.6712,
    "Recreation": 0.5953,
    "Reinsurance": 0.7660,
    "Restaurant/Dining": 0.5953,
    "Retail (Automotive)": 0.2282,
    "Retail (Building Supply)": 0.3261,
    "Retail (Distributors)": 0.2282,
    "Retail (General)": 0.3316,
    "Retail (Grocery and Food)": 0.2282,
    "Retail (Online)": 0.4375,
    "Retail (Special Lines)": 0.4289,
    "Rubber& Tires": 0.2372,
    "Semiconductor": 0.5653,
    "Semiconductor Equip": 0.4339,
    "Shipbuilding & Marine": 0.2149,
    "Shoe": 0.4289,
    "Software (Entertainment)": 0.6897,
    "Software (Internet)": 0.6897,
    "Software (System & Application)": 0.6897,
    "Steel": 0.2676,
    "Telecom (Wireless)": 0.5953,
    "Telecom. Equipment": 0.4339,
    "Telecom. Services": 0.5953,
    "Tobacco": 0.5453,
    "Transportation": 0.2592,
    "Transportation (Railroads)": 0.2592,
    "Trucking": 0.2592,
    "Utility (General)": 0.4305,
    "Utility (Water)": 0.4305,
    "Total Market": 0.3756,
  };
  
  export const industryNetProfitMargins: IndustryData = {
    "Advertising": 0.0718,
    "Aerospace/Defense": 0.0703,
    "Air Transport": 0.0404,
    "Apparel": 0.0775,
    "Auto & Truck": 0.0413,
    "Auto Parts": 0.0597,
    "Bank (Money Center)": 0.2727,
    "Banks (Regional)": 0.2727,
    "Beverage (Alcoholic)": 0.1842,
    "Beverage (Soft)": 0.1929,
    "Broadcasting": 0.1518,
    "Brokerage & Investment Banking": 0.1935,
    "Building Materials": 0.0952,
    "Business & Consumer Services": 0.0651,
    "Cable TV": 0.1518,
    "Chemical (Basic)": 0.0938,
    "Chemical (Diversified)": 0.0930,
    "Chemical (Specialty)": 0.0829,
    "Coal & Related Energy": 0.0589,
    "Computer Services": 0.0904,
    "Computers/Peripherals": 0.1181,
    "Construction Supplies": 0.0757,
    "Diversified": 0.0757,
    "Drugs (Biotechnology)": 0.0608,
    "Drugs (Pharmaceutical)": 0.1326,
    "Education": 0.0804,
    "Electrical Equipment": 0.0812,
    "Electronics (Consumer & Office)": 0.1181,
    "Electronics (General)": 0.0876,
    "Engineering/Construction": 0.0465,
    "Entertainment": 0.1518,
    "Environmental & Waste Services": 0.0558,
    "Farming/Agriculture": 0.0589,
    "Financial Svcs. (Non-bank & Insurance)": 0.2727,
    "Food Processing": 0.0658,
    "Food Wholesalers": 0.0165,
    "Furn/Home Furnishings": 0.0775,
    "Green & Renewable Energy": 0.0754,
    "Healthcare Products": 0.1174,
    "Healthcare Support Services": 0.0558,
    "Heathcare Information and Technology": 0.1174,
    "Homebuilding": 0.0703,
    "Hospitals/Healthcare Facilities": 0.1174,
    "Hotel/Gaming": 0.1518,
    "Household Products": 0.0775,
    "Information Services": 0.1934,
    "Insurance (General)": 0.2727,
    "Insurance (Life)": 0.2727,
    "Insurance (Prop/Cas.)": 0.2727,
    "Investments & Asset Management": 0.2727,
    "Machinery": 0.0757,
    "Metals & Mining": 0.0589,
    "Office Equipment & Services": 0.1181,
    "Oil/Gas (Integrated)": 0.0589,
    "Oil/Gas (Production and Exploration)": 0.0589,
    "Oil/Gas Distribution": 0.0589,
    "Oilfield Svcs/Equip.": 0.0589,
    "Packaging & Container": 0.0597,
    "Paper/Forest Products": 0.0589,
    "Power": 0.0754,
    "Precious Metals": 0.0589,
    "Publishing & Newspapers": 0.1518,
    "R.E.I.T.": 0.2058,
    "Real Estate (Development)": 0.0757,
    "Real Estate (General/Diversified)": 0.0757,
    "Real Estate (Operations & Services)": 0.2058,
    "Recreation": 0.1518,
    "Reinsurance": 0.2727,
    "Restaurant/Dining": 0.1518,
    "Retail (Automotive)": 0.0276,
    "Retail (Building Supply)": 0.0757,
    "Retail (Distributors)": 0.0276,
    "Retail (General)": 0.0381,
    "Retail (Grocery and Food)": 0.0276,
    "Retail (Online)": 0.0425,
    "Retail (Special Lines)": 0.0775,
    "Rubber& Tires": 0.0597,
    "Semiconductor": 0.1174,
    "Semiconductor Equip": 0.1181,
    "Shipbuilding & Marine": 0.0703,
    "Shoe": 0.0775,
    "Software (Entertainment)": 0.1934,
    "Software (Internet)": 0.1934,
    "Software (System & Application)": 0.1934,
    "Steel": 0.0589,
    "Telecom (Wireless)": 0.1518,
    "Telecom. Equipment": 0.1181,
    "Telecom. Services": 0.1518,
    "Tobacco": 0.1842,
    "Transportation": 0.0404,
    "Transportation (Railroads)": 0.0404,
    "Trucking": 0.0404,
    "Utility (General)": 0.0754,
    "Utility (Water)": 0.0754,
    "Total Market": 0.0940,
  };

// NYUStearnsData.ts

// Revenue Multiples (EV/Sales)
export const industryRevenueMultiples: IndustryData = {
  "Advertising": 1.76,
  "Aerospace/Defense": 1.67,
  "Air Transport": 1.99,
  "Apparel": 1.44,
  "Auto & Truck": 1.01,
  "Auto Parts": 1.13,
  "Bank (Money Center)": 2.89,
  "Banks (Regional)": 3.04,
  "Beverage (Alcoholic)": 5.23,
  "Beverage (Soft)": 4.76,
  "Broadcasting": 2.68,
  "Brokerage & Investment Banking": 2.13,
  "Building Materials": 1.85,
  "Business & Consumer Services": 2.54,
  "Cable TV": 3.16,
  "Chemical (Basic)": 1.53,
  "Chemical (Diversified)": 2.18,
  "Chemical (Specialty)": 2.91,
  "Coal & Related Energy": 1.23,
  "Computer Services": 3.37,
  "Computers/Peripherals": 2.51,
  "Construction Supplies": 1.95,
  "Diversified": 2.08,
  "Drugs (Biotechnology)": 7.32,
  "Drugs (Pharmaceutical)": 4.39,
  "Education": 4.01,
  "Electrical Equipment": 2.13,
  "Electronics (Consumer & Office)": 1.54,
  "Electronics (General)": 2.26,
  "Engineering/Construction": 0.85,
  "Entertainment": 3.23,
  "Environmental & Waste Services": 3.02,
  "Farming/Agriculture": 1.63,
  "Financial Svcs. (Non-bank & Insurance)": 4.21,
  "Food Processing": 1.94,
  "Food Wholesalers": 0.73,
  "Furn/Home Furnishings": 1.15,
  "Green & Renewable Energy": 8.48,
  "Healthcare Products": 4.38,
  "Healthcare Support Services": 1.27,
  "Heathcare Information and Technology": 4.79,
  "Homebuilding": 1.26,
  "Hospitals/Healthcare Facilities": 1.65,
  "Hotel/Gaming": 3.01,
  "Household Products": 3.48,
  "Information Services": 6.94,
  "Insurance (General)": 1.22,
  "Insurance (Life)": 1.42,
  "Insurance (Prop/Cas.)": 1.44,
  "Investments & Asset Management": 3.85,
  "Machinery": 1.88,
  "Metals & Mining": 1.85,
  "Office Equipment & Services": 1.33,
  "Oil/Gas (Integrated)": 1.22,
  "Oil/Gas (Production and Exploration)": 2.21,
  "Oil/Gas Distribution": 0.59,
  "Oilfield Svcs/Equip.": 1.66,
  "Packaging & Container": 1.43,
  "Paper/Forest Products": 1.18,
  "Power": 2.39,
  "Precious Metals": 3.95,
  "Publishing & Newspapers": 1.53,
  "R.E.I.T.": 8.7,
  "Real Estate (Development)": 2.01,
  "Real Estate (General/Diversified)": 3.46,
  "Real Estate (Operations & Services)": 4.64,
  "Recreation": 2.84,
  "Reinsurance": 1.32,
  "Restaurant/Dining": 2.86,
  "Retail (Automotive)": 0.64,
  "Retail (Building Supply)": 1.95,
  "Retail (Distributors)": 0.87,
  "Retail (General)": 0.92,
  "Retail (Grocery and Food)": 0.71,
  "Retail (Online)": 3.11,
  "Retail (Special Lines)": 1.38,
  "Rubber& Tires": 1.06,
  "Semiconductor": 4.43,
  "Semiconductor Equip": 4.38,
  "Shipbuilding & Marine": 0.87,
  "Shoe": 1.98,
  "Software (Entertainment)": 5.66,
  "Software (Internet)": 7.38,
  "Software (System & Application)": 7.24,
  "Steel": 0.99,
  "Telecom (Wireless)": 2.77,
  "Telecom. Equipment": 2.91,
  "Telecom. Services": 2.63,
  "Tobacco": 4.72,
  "Transportation": 1.84,
  "Transportation (Railroads)": 5.38,
  "Trucking": 1.65,
  "Utility (General)": 3.27,
  "Utility (Water)": 5.41,
  "Total Market": 2.57,
};


// NYUStearnsData.ts

// ... (previous code remains the same)

// Profit Multiples (derived from Price-to-Earnings ratios)
export const industryProfitMultiples: IndustryData = {
    "Advertising": 21.69,
    "Aerospace/Defense": 22.37,
    "Air Transport": 20.27,
    "Apparel": 23.64,
    "Auto & Truck": 14.39,
    "Auto Parts": 21.44,
    "Bank (Money Center)": 10.02,
    "Banks (Regional)": 11.88,
    "Beverage (Alcoholic)": 23.84,
    "Beverage (Soft)": 24.16,
    "Broadcasting": 16.93,
    "Brokerage & Investment Banking": 13.67,
    "Building Materials": 20.69,
    "Business & Consumer Services": 25.07,
    "Cable TV": 16.93,
    "Chemical (Basic)": 14.73,
    "Chemical (Diversified)": 19.19,
    "Chemical (Specialty)": 23.15,
    "Coal & Related Energy": 9.22,
    "Computer Services": 24.18,
    "Computers/Peripherals": 17.21,
    "Construction Supplies": 20.94,
    "Diversified": 16.71,
    "Drugs (Biotechnology)": 60.26,
    "Drugs (Pharmaceutical)": 29.24,
    "Education": 27.03,
    "Electrical Equipment": 22.04,
    "Electronics (Consumer & Office)": 15.53,
    "Electronics (General)": 21.73,
    "Engineering/Construction": 17.22,
    "Entertainment": 20.22,
    "Environmental & Waste Services": 39.05,
    "Farming/Agriculture": 21.45,
    "Financial Svcs. (Non-bank & Insurance)": 15.23,
    "Food Processing": 21.76,
    "Food Wholesalers": 25.50,
    "Furn/Home Furnishings": 17.32,
    "Green & Renewable Energy": 24.71,
    "Healthcare Products": 27.31,
    "Healthcare Support Services": 20.85,
    "Heathcare Information and Technology": 30.34,
    "Homebuilding": 10.21,
    "Hospitals/Healthcare Facilities": 22.61,
    "Hotel/Gaming": 22.81,
    "Household Products": 22.62,
    "Information Services": 28.84,
    "Insurance (General)": 12.96,
    "Insurance (Life)": 10.03,
    "Insurance (Prop/Cas.)": 15.51,
    "Investments & Asset Management": 16.94,
    "Machinery": 21.90,
    "Metals & Mining": 15.91,
    "Office Equipment & Services": 14.15,
    "Oil/Gas (Integrated)": 14.17,
    "Oil/Gas (Production and Exploration)": 16.05,
    "Oil/Gas Distribution": 22.35,
    "Oilfield Svcs/Equip.": 19.48,
    "Packaging & Container": 17.71,
    "Paper/Forest Products": 14.80,
    "Power": 16.23,
    "Precious Metals": 26.15,
    "Publishing & Newspapers": 15.30,
    "R.E.I.T.": 38.14,
    "Real Estate (Development)": 16.77,
    "Real Estate (General/Diversified)": 29.83,
    "Real Estate (Operations & Services)": 23.76,
    "Recreation": 22.76,
    "Reinsurance": 11.97,
    "Restaurant/Dining": 24.51,
    "Retail (Automotive)": 16.25,
    "Retail (Building Supply)": 19.97,
    "Retail (Distributors)": 18.05,
    "Retail (General)": 18.99,
    "Retail (Grocery and Food)": 19.32,
    "Retail (Online)": 52.14,
    "Retail (Special Lines)": 19.81,
    "Rubber& Tires": 15.59,
    "Semiconductor": 24.51,
    "Semiconductor Equip": 22.18,
    "Shipbuilding & Marine": 14.77,
    "Shoe": 23.55,
    "Software (Entertainment)": 38.80,
    "Software (Internet)": 34.61,
    "Software (System & Application)": 32.65,
    "Steel": 9.73,
    "Telecom (Wireless)": 15.69,
    "Telecom. Equipment": 20.56,
    "Telecom. Services": 15.62,
    "Tobacco": 13.42,
    "Transportation": 18.95,
    "Transportation (Railroads)": 19.44,
    "Trucking": 20.53,
    "Utility (General)": 18.02,
    "Utility (Water)": 29.05,
    "Total Market": 22.64,
  };