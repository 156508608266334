import React, { useState } from 'react';
import { confirmResetPassword } from 'aws-amplify/auth';
import { Flex, Text, TextField, Button, Heading, useTheme } from '@aws-amplify/ui-react';
import { AuthState } from '../types/auth';

const purpleColor = '#B388FF';
const yellowColor = '#FFD54F';
const mediumSpacing = '1rem';

interface ResetPasswordProps {
    onStateChange: (state: AuthState, username?: string) => void;
    username: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ onStateChange, username }) => {
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const { tokens } = useTheme();

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await confirmResetPassword({ username, newPassword, confirmationCode: code });
            onStateChange(AuthState.SignIn);
        } catch (err: any) {
            setError(err.message || 'An error occurred while resetting password');
        }
    };

    return (
        <Flex direction="row" height="100vh">
            <Flex direction="column" flex="0 0 40%" padding={tokens.space.large} backgroundColor="white" justifyContent="center">
                <Flex direction="column" width="100%" maxWidth="400px" margin="0 auto">
                    <Heading level={3} padding={tokens.space.medium} textAlign="center">Set new password</Heading>
                    <form onSubmit={handleResetPassword}>
                        <TextField
                            label="Confirmation Code"
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                        <TextField
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <Button type="submit" variation="primary" width="100%" marginTop={tokens.space.medium}>
                            Set New Password
                        </Button>
                    </form>
                    <Text
                        color={tokens.colors.purple[60]}
                        fontSize={tokens.fontSizes.small}
                        fontWeight={tokens.fontWeights.bold}
                        onClick={() => onStateChange(AuthState.SignIn)}
                        style={{ cursor: 'pointer', marginTop: mediumSpacing, textAlign: 'center' }}
                    >
                        Back to Sign In
                    </Text>
                    {error && (
                        <Text color={tokens.colors.red[60]} marginTop={tokens.space.medium}>
                            {error}
                        </Text>
                    )}
                </Flex>
            </Flex>
            {/* Branding message column (same as SignIn.tsx) */}
            <Flex
                direction="column"
                flex="0 0 60%"
                backgroundColor={"#233d4d"}
                padding={tokens.space.xl}
                justifyContent="center"
            >
                <Heading level={1} color={tokens.colors.white}>Welcome to The Better Exit</Heading>
                <Text fontSize={tokens.fontSizes.large} color="#f4f4f9" marginTop={tokens.space.medium}>
                    Exit your business like a boss with The Better Exit's all-in-one strategy toolkit!
                </Text>
                <Text fontSize={tokens.fontSizes.xxl} fontWeight={tokens.fontWeights.bold} marginTop={tokens.space.xl}>
                    <span style={{ color: purpleColor }}>Stop waiting for someday, </span><br />
                    <span style={{ color: yellowColor }}>Start planning for Your Better Exit today!</span>
                </Text>
            </Flex>
        </Flex>
    );
};

export default ResetPassword;