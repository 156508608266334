import React from 'react';
import { Flex, Image, Text, View, Button } from "@aws-amplify/ui-react";
import exitExportShareIcon from '../assets/img/354903-exit-export-share-icon-1.svg';

import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/css/main.css';

const HEADER_HEIGHT = '60px';

interface HeaderProps {
    signOut?: () => void;
    user?: any; // Replace 'any' with your user type if available
  }

  const Header: React.FC<HeaderProps> = ({ signOut, user }) => {
    return (
      <View as="header" className="header d-flex align-items-center fixed-top">
        <Flex className="container position-relative d-flex align-items-center justify-content-between">
          <Flex as="a" href="https://thebetterexit.com" className="logo d-flex align-items-center me-auto me-xl-0">
            <Image
              src={exitExportShareIcon}
              alt="The Better Exit Logo"
              className="exit-export-share-icon-1"
              width="auto"
              height={HEADER_HEIGHT}
            />
            <Text className="thebetterexit" fontSize="1.5rem" fontWeight="bold" marginLeft="0.5rem">
              TheBetterExit.com
            </Text>
          </Flex>
          
          <Flex as="nav" className="header-nav ms-auto">
            <Flex as="ul">
              <li><a href="#hero" className="active">Home</a></li>
              {user ? (
                <>
                  <li><a href="#todo">Todo</a></li>
                  <li><Button onClick={signOut}>Logoff</Button></li>
                </>
              ) : (
                <li><a href="#login">Login</a></li>
              )}
            </Flex>
          </Flex>
  
          <Flex className="header-social-links">
            <a href="https://www.facebook.com/thebetterexit" className="facebook"><i className="bi bi-facebook"></i></a>
            <a href="https://www.linkedin.com/company/thebetterexit" className="linkedin"><i className="bi bi-linkedin"></i></a>
          </Flex>
        </Flex>
      </View>
    );
  };
  

export default Header;