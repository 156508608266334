import React, { useState, useEffect } from 'react';
import { TextField } from "@aws-amplify/ui-react";

const CurrencyTextField = (props) => {
  const [displayValue, setDisplayValue] = useState('');

  const formatCurrency = (value) => {
    if (value === null || value === undefined || value === '') return '';
    const number = parseFloat(value);
    return isNaN(number) ? '' : new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  const parseCurrency = (value) => {
    const parsed = parseFloat(value.replace(/[^\d.-]/g, ''));
    return isNaN(parsed) ? null : parsed;
  };

  useEffect(() => {
    setDisplayValue(formatCurrency(props.value));
  }, [props.value]);

  const handleChange = (e) => {
    const rawValue = e.target.value;
    setDisplayValue(rawValue);
    
    if (props.onChange) {
      const numericValue = parseCurrency(rawValue);
      props.onChange({
        target: {
          value: numericValue
        }
      });
    }
  };

  const handleBlur = (e) => {
    const numericValue = parseCurrency(displayValue);
    const formattedValue = formatCurrency(numericValue);
    setDisplayValue(formattedValue);
    
    if (props.onBlur) {
      props.onBlur(e);
    }
    
    if (props.onChange) {
      props.onChange({
        target: {
          value: numericValue
        }
      });
    }
  };

  return (
    <TextField
      {...props}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      inputMode="decimal"
    />
  );
};

export default CurrencyTextField;