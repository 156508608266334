import React, { useState } from 'react';
import { signIn, signInWithRedirect, fetchUserAttributes } from 'aws-amplify/auth';
import { Flex, Text, TextField, Button, Heading, useTheme } from '@aws-amplify/ui-react';
import { AuthState } from '../types/auth';

const purpleColor = '#B388FF';
const yellowColor = '#FFD54F';

import '../assets/css/main.css';

interface SignInProps {
    onStateChange: (state: AuthState, username?: string) => void;
    onSignIn: () => void;
}

const SignIn: React.FC<SignInProps> = ({ onStateChange, onSignIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { tokens } = useTheme();

    const handleSignIn = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        try {
            console.log('Attempting sign in for:', email);
            const { isSignedIn, nextStep } = await signIn({ username: email, password });

            console.log('Sign in result:', { isSignedIn, nextStep });
            if (isSignedIn) {
                console.log('User signed in successfully');
                const userAttributes = await fetchUserAttributes();
                console.log('User attributes:', userAttributes);
                onSignIn();
            } else if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                console.log('User needs to confirm sign up');
                onStateChange(AuthState.ConfirmSignUp, email);
            } else {
                console.log('Unexpected next step:', nextStep);
                setError('Unable to sign in. Please try again.');
            }
        } catch (err: any) {
            console.error('Sign in error:', err);
            setError(err.message || 'An error occurred during sign in');
        }
    };

    const handleSocialSignIn = async (provider: 'Google' | 'Amazon') => {
        try {
            await signInWithRedirect({ provider: provider as any });
        } catch (err: any) {
            setError(err.message || 'An error occurred during social sign in');
        }
    };

    return (
        <Flex direction="row" height="100vh">
            {/* Sign-in form column */}
            <Flex direction="column" flex="0 0 40%" padding={tokens.space.large} backgroundColor="white" justifyContent="center">
                <Flex direction="column" width="100%" maxWidth="400px" margin="0 auto">
                    <Heading level={3} padding={tokens.space.medium} textAlign="center">Sign in to The Better Exit</Heading>
                    <form onSubmit={handleSignIn}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Button type="submit" variation="primary" width="100%" marginTop={tokens.space.medium}>
                            Sign in
                        </Button>
                    </form>
                    <Flex
                        direction="row"
                        marginTop={tokens.space.medium}
                        backgroundColor="#"
                    >
                        <Button onClick={() => handleSocialSignIn('Google')} flex={1} marginRight={tokens.space.xs}>
                            Sign in with Google
                        </Button>
                        <Button onClick={() => handleSocialSignIn('Amazon')} flex={1} marginLeft={tokens.space.xs}>
                            Sign in with Amazon
                        </Button>
                    </Flex>
                    <Flex direction="row" justifyContent="space-between" marginTop={tokens.space.large}>
                        <Text
                            color={tokens.colors.purple[60]}
                            fontSize={tokens.fontSizes.small}
                            fontWeight={tokens.fontWeights.bold}
                            onClick={() => onStateChange(AuthState.ForgotPassword)}
                            style={{ cursor: 'pointer' }}
                        >
                            Forgot password?
                        </Text>
                        <Text
                            color={tokens.colors.yellow[60]}
                            fontSize={tokens.fontSizes.small}
                            fontWeight={tokens.fontWeights.bold}
                            onClick={() => onStateChange(AuthState.SignUp)}
                            style={{ cursor: 'pointer' }}
                        >
                            Don't have an account? Sign up
                        </Text>
                    </Flex>
                    {error && (
                        <Text color={tokens.colors.red[60]} marginTop={tokens.space.medium}>
                            {error}
                        </Text>
                    )}
                </Flex>
            </Flex>

            {/* Branding message column */}
            <Flex
                direction="column"
                flex="0 0 60%"
                backgroundColor={"#233d4d"}
                padding={tokens.space.xl}
                justifyContent="center"
            >
                <Heading level={1} color={tokens.colors.white}>Welcome to The Better Exit</Heading>
                <Text fontSize={tokens.fontSizes.large} color="#f4f4f9" marginTop={tokens.space.medium}>
                    Exit your business like a boss with The Better Exit's all-in-one strategy toolkit!
                </Text>
                <Text fontSize={tokens.fontSizes.xxl} fontWeight={tokens.fontWeights.bold} marginTop={tokens.space.xl}>
                    <span style={{ color: purpleColor }}>Stop waiting for someday, </span><br />
                    <span style={{ color: yellowColor }}>Start planning for Your Better Exit today!</span>
                </Text>
            </Flex>
        </Flex>
    );
};

export default SignIn;