// App.tsx
import React, { useState, useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import Authentication from './components/Authentication';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import awsconfig from '../amplify_outputs.json';

import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/aos/aos.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/css/main.css';
import './components/custom-auth.css';

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState<any | null>(null);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (err) {
      setUser(null);
    }
  }
  
  async function handleSignOut() {
    try {
      await signOut();
      setUser(null);
    } catch (err) {
      console.error('Error signing out:', err);
    }
  }

  return (
    <div className="App">
      {user ? (
        <div>
          <Header />
          <Dashboard user={user} signOut={handleSignOut} />
          <Footer />
        </div>
      ) : (
        <Authentication onSignIn={checkUser} />
      )}
    </div>
  );
}

export default App;