import React, { useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { Flex, Text, TextField, Button, Heading, useTheme } from '@aws-amplify/ui-react';
import { AuthState } from '../types/auth';
import { generateClient } from 'aws-amplify/api';
import type { Schema } from "../../amplify/data/resource";


const client = generateClient<Schema>();

const purpleColor = '#B388FF';
const yellowColor = '#FFD54F';
const mediumSpacing = '1rem';


interface SignUpProps {
    onStateChange: (state: AuthState, username?: string) => void;
}

const SignUp: React.FC<SignUpProps> = ({ onStateChange }) => {

    // const [user, setUser] = useState<Array<Schema["User"]["type"]>>([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { tokens } = useTheme();

    /* User Model:
      User: a
        .model({
        userId: a.id().required(),
        userType: a.enum(['BUSINESS_OWNER', 'INVESTOR_ENTREPRENEUR', 'EXIT_PROFESSIONAL', 'JOB_SEEKER']),
        email: a.string().required(),
        name: a.string(),
        company: a.string(),
        planLevel: a.enum(['FREE', 'BASIC', 'PREMIUM', 'ENTERPRISE']),
        subscriptionStatus: a.enum(['ACTIVE', 'INACTIVE', 'PENDING', 'CANCELLED']),
        profilePicture: a.string(),
        linkedInProfile: a.string(),
        stripe_api_key: a.string(),
        stripe_secret: a.string(),
        ownedBusinesses: a.hasMany('Business', 'ownerId'),
        createdAt: a.datetime().required(),
        updatedAt: a.datetime(),
        })
        .identifier(['userId'])
        .authorization((allow) => [allow.owner(), allow.publicApiKey(), allow.guest().to(['create'])]),
    */

    // useEffect(() => {
    //     client.models.User.observeQuery().subscribe({
    //         next: (data) => setUser([...data.items]),
    //     });
    // }, []);

    async function createUser({ userId, email, name }: { userId: string, email: string, name: string }) {
        try {
            const newUser = await client.models.User.create({
                id: userId,
                userId,
                userType: 'BUSINESS_OWNER',
                email,
                name,
                planLevel: 'FREE',
                subscriptionStatus: 'ACTIVE',
                createdAt: new Date().toISOString()
            });
            console.log('User record created:', newUser);
            return newUser;
        } catch (error) {
            console.error('Error creating user record:', error);
            throw error;
        }
    }

    function deleteUser(userId: string) {
        console.log("delete [User][" + userId + "]");
        client.models.User.delete({ id: userId });
    }

    const handleSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const { userId } = await signUp({ username: email, password });

            if (userId) {
                try {
                    await createUser({ userId, email, name });
                    console.log('User created successfully');
                    onStateChange(AuthState.ConfirmSignUp, email);
                } catch (dbError) {
                    deleteUser(userId);
                    console.log("User deleted:", userId);
                    console.error('Error creating user in database:', dbError);
                    setError('An error occurred while creating your account. Please try again or contact support if the problem persists.');
                    // Log the error for internal tracking
                    console.error('Failed to create user in database. Cognito user might exist without a corresponding database entry.', { email, userId });
                }
            } else {
                console.error('Cognito failed to return a userId during user signup for ', { email, userId });
            }
        } catch (authError: any) {
            handleSignUpError(authError);
        }
    };


    const handleSignUpError = (error: any) => {
        console.error('SignUp error:', error);
        if (error.name === 'UsernameExistsException') {
            setError('An account with this email already exists.');
        } else if (error.name === 'InvalidPasswordException') {
            setError('Password does not meet the requirements. It should be at least 8 characters long and contain uppercase, lowercase, numbers, and special characters.');
        } else if (error.name === 'InvalidParameterException') {
            setError('Invalid email format. Please enter a valid email address.');
        } else {
            setError(error.message || 'An error occurred during sign up. Please try again.');
        }
    };

    return (
        <Flex direction="row" height="100vh">
            <Flex direction="column" flex="0 0 40%" padding={tokens.space.large} backgroundColor="white" justifyContent="center">
                <Flex direction="column" width="100%" maxWidth="400px" margin="0 auto">
                    <Heading level={3} padding={tokens.space.medium} textAlign="center">Create your account</Heading>
                    <form onSubmit={handleSignUp}>
                        <TextField
                            label="Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <TextField
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <Button type="submit" variation="primary" width="100%" marginTop={tokens.space.medium}>
                            Sign up
                        </Button>
                    </form>
                    <Text
                        color={purpleColor}
                        fontSize={tokens.fontSizes.small}
                        fontWeight={tokens.fontWeights.bold}
                        onClick={() => onStateChange(AuthState.SignIn)}
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                        marginTop={mediumSpacing}
                    >
                        Already have an account? Sign in
                    </Text>
                    {error && (
                        <Text color={tokens.colors.red[60]} marginTop={tokens.space.medium}>
                            {error}
                        </Text>
                    )}
                </Flex>
            </Flex>
            {/* Branding message column (same as SignIn.tsx) */}
            <Flex
                direction="column"
                flex="0 0 60%"
                backgroundColor={"#233d4d"}
                padding={tokens.space.xl}
                justifyContent="center"
            >
                <Heading level={1} color={tokens.colors.white}>Join The Better Exit</Heading>
                <Text fontSize={tokens.fontSizes.large} color="#f4f4f9" marginTop={tokens.space.medium}>
                    Start your journey to a successful business exit with The Better Exit's comprehensive toolkit!
                </Text>
                <Text fontSize={tokens.fontSizes.xxl} fontWeight={tokens.fontWeights.bold} marginTop={tokens.space.xl}>
                    <span style={{ color: purpleColor }}>Stop waiting for someday, </span><br />
                    <span style={{ color: yellowColor }}>Start planning for Your Better Exit today!</span>
                </Text>
            </Flex>
        </Flex>
    );
};

export default SignUp;