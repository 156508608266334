/* eslint-disable */
"use client";
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getUser } from "./graphql/queries";
import { updateUser } from "./graphql/mutations";
const client = generateClient();
export default function UserUpdateForm(props) {
  const {
    id: idProp,
    user: userModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    userId: "",
    userType: "",
    email: "",
    name: "",
    company: "",
    planLevel: "",
    subscriptionStatus: "",
    profilePicture: "",
    linkedInProfile: "",
    stripe_api_key: "",
    stripe_secret: "",
    createdAt: "",
    updatedAt: "",
  };
  const [userId, setUserId] = React.useState(initialValues.userId);
  const [userType, setUserType] = React.useState(initialValues.userType);
  const [email, setEmail] = React.useState(initialValues.email);
  const [name, setName] = React.useState(initialValues.name);
  const [company, setCompany] = React.useState(initialValues.company);
  const [planLevel, setPlanLevel] = React.useState(initialValues.planLevel);
  const [subscriptionStatus, setSubscriptionStatus] = React.useState(
    initialValues.subscriptionStatus
  );
  const [profilePicture, setProfilePicture] = React.useState(
    initialValues.profilePicture
  );
  const [linkedInProfile, setLinkedInProfile] = React.useState(
    initialValues.linkedInProfile
  );
  const [stripe_api_key, setStripe_api_key] = React.useState(
    initialValues.stripe_api_key
  );
  const [stripe_secret, setStripe_secret] = React.useState(
    initialValues.stripe_secret
  );
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [updatedAt, setUpdatedAt] = React.useState(initialValues.updatedAt);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = userRecord
      ? { ...initialValues, ...userRecord }
      : initialValues;
    setUserId(cleanValues.userId);
    setUserType(cleanValues.userType);
    setEmail(cleanValues.email);
    setName(cleanValues.name);
    setCompany(cleanValues.company);
    setPlanLevel(cleanValues.planLevel);
    setSubscriptionStatus(cleanValues.subscriptionStatus);
    setProfilePicture(cleanValues.profilePicture);
    setLinkedInProfile(cleanValues.linkedInProfile);
    setStripe_api_key(cleanValues.stripe_api_key);
    setStripe_secret(cleanValues.stripe_secret);
    setCreatedAt(cleanValues.createdAt);
    setUpdatedAt(cleanValues.updatedAt);
    setErrors({});
  };
  const [userRecord, setUserRecord] = React.useState(userModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getUser.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getUser
        : userModelProp;
      setUserRecord(record);
    };
    queryData();
  }, [idProp, userModelProp]);
  React.useEffect(resetStateValues, [userRecord]);
  const validations = {
    userId: [{ type: "Required" }],
    userType: [],
    email: [{ type: "Required" }],
    name: [],
    company: [],
    planLevel: [],
    subscriptionStatus: [],
    profilePicture: [],
    linkedInProfile: [],
    stripe_api_key: [],
    stripe_secret: [],
    createdAt: [{ type: "Required" }],
    updatedAt: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          userId,
          userType: userType ?? null,
          email,
          name: name ?? null,
          company: company ?? null,
          planLevel: planLevel ?? null,
          subscriptionStatus: subscriptionStatus ?? null,
          profilePicture: profilePicture ?? null,
          linkedInProfile: linkedInProfile ?? null,
          stripe_api_key: stripe_api_key ?? null,
          stripe_secret: stripe_secret ?? null,
          createdAt,
          updatedAt: updatedAt ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateUser.replaceAll("__typename", ""),
            variables: {
              input: {
                id: userRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "UserUpdateForm")}
      {...rest}
    >
      <TextField
        label="User id"
        isRequired={true}
        isReadOnly={false}
        value={userId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId: value,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.userId ?? value;
          }
          if (errors.userId?.hasError) {
            runValidationTasks("userId", value);
          }
          setUserId(value);
        }}
        onBlur={() => runValidationTasks("userId", userId)}
        errorMessage={errors.userId?.errorMessage}
        hasError={errors.userId?.hasError}
        {...getOverrideProps(overrides, "userId")}
      ></TextField>
      <SelectField
        label="User type"
        placeholder="Please select an option"
        isDisabled={false}
        value={userType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType: value,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.userType ?? value;
          }
          if (errors.userType?.hasError) {
            runValidationTasks("userType", value);
          }
          setUserType(value);
        }}
        onBlur={() => runValidationTasks("userType", userType)}
        errorMessage={errors.userType?.errorMessage}
        hasError={errors.userType?.hasError}
        {...getOverrideProps(overrides, "userType")}
      >
        <option
          children="Business owner"
          value="BUSINESS_OWNER"
          {...getOverrideProps(overrides, "userTypeoption0")}
        ></option>
        <option
          children="Investor entrepreneur"
          value="INVESTOR_ENTREPRENEUR"
          {...getOverrideProps(overrides, "userTypeoption1")}
        ></option>
        <option
          children="Exit professional"
          value="EXIT_PROFESSIONAL"
          {...getOverrideProps(overrides, "userTypeoption2")}
        ></option>
        <option
          children="Job seeker"
          value="JOB_SEEKER"
          {...getOverrideProps(overrides, "userTypeoption3")}
        ></option>
      </SelectField>
      <TextField
        label="Email"
        isRequired={true}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email: value,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name: value,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Company"
        isRequired={false}
        isReadOnly={false}
        value={company}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company: value,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.company ?? value;
          }
          if (errors.company?.hasError) {
            runValidationTasks("company", value);
          }
          setCompany(value);
        }}
        onBlur={() => runValidationTasks("company", company)}
        errorMessage={errors.company?.errorMessage}
        hasError={errors.company?.hasError}
        {...getOverrideProps(overrides, "company")}
      ></TextField>
      <SelectField
        label="Plan level"
        placeholder="Please select an option"
        isDisabled={false}
        value={planLevel}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel: value,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.planLevel ?? value;
          }
          if (errors.planLevel?.hasError) {
            runValidationTasks("planLevel", value);
          }
          setPlanLevel(value);
        }}
        onBlur={() => runValidationTasks("planLevel", planLevel)}
        errorMessage={errors.planLevel?.errorMessage}
        hasError={errors.planLevel?.hasError}
        {...getOverrideProps(overrides, "planLevel")}
      >
        <option
          children="Free"
          value="FREE"
          {...getOverrideProps(overrides, "planLeveloption0")}
        ></option>
        <option
          children="Basic"
          value="BASIC"
          {...getOverrideProps(overrides, "planLeveloption1")}
        ></option>
        <option
          children="Premium"
          value="PREMIUM"
          {...getOverrideProps(overrides, "planLeveloption2")}
        ></option>
        <option
          children="Enterprise"
          value="ENTERPRISE"
          {...getOverrideProps(overrides, "planLeveloption3")}
        ></option>
      </SelectField>
      <SelectField
        label="Subscription status"
        placeholder="Please select an option"
        isDisabled={false}
        value={subscriptionStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus: value,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.subscriptionStatus ?? value;
          }
          if (errors.subscriptionStatus?.hasError) {
            runValidationTasks("subscriptionStatus", value);
          }
          setSubscriptionStatus(value);
        }}
        onBlur={() =>
          runValidationTasks("subscriptionStatus", subscriptionStatus)
        }
        errorMessage={errors.subscriptionStatus?.errorMessage}
        hasError={errors.subscriptionStatus?.hasError}
        {...getOverrideProps(overrides, "subscriptionStatus")}
      >
        <option
          children="Active"
          value="ACTIVE"
          {...getOverrideProps(overrides, "subscriptionStatusoption0")}
        ></option>
        <option
          children="Inactive"
          value="INACTIVE"
          {...getOverrideProps(overrides, "subscriptionStatusoption1")}
        ></option>
        <option
          children="Pending"
          value="PENDING"
          {...getOverrideProps(overrides, "subscriptionStatusoption2")}
        ></option>
        <option
          children="Cancelled"
          value="CANCELLED"
          {...getOverrideProps(overrides, "subscriptionStatusoption3")}
        ></option>
      </SelectField>
      <TextField
        label="Profile picture"
        isRequired={false}
        isReadOnly={false}
        value={profilePicture}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture: value,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.profilePicture ?? value;
          }
          if (errors.profilePicture?.hasError) {
            runValidationTasks("profilePicture", value);
          }
          setProfilePicture(value);
        }}
        onBlur={() => runValidationTasks("profilePicture", profilePicture)}
        errorMessage={errors.profilePicture?.errorMessage}
        hasError={errors.profilePicture?.hasError}
        {...getOverrideProps(overrides, "profilePicture")}
      ></TextField>
      <TextField
        label="Linked in profile"
        isRequired={false}
        isReadOnly={false}
        value={linkedInProfile}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile: value,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.linkedInProfile ?? value;
          }
          if (errors.linkedInProfile?.hasError) {
            runValidationTasks("linkedInProfile", value);
          }
          setLinkedInProfile(value);
        }}
        onBlur={() => runValidationTasks("linkedInProfile", linkedInProfile)}
        errorMessage={errors.linkedInProfile?.errorMessage}
        hasError={errors.linkedInProfile?.hasError}
        {...getOverrideProps(overrides, "linkedInProfile")}
      ></TextField>
      <TextField
        label="Stripe api key"
        isRequired={false}
        isReadOnly={false}
        value={stripe_api_key}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key: value,
              stripe_secret,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.stripe_api_key ?? value;
          }
          if (errors.stripe_api_key?.hasError) {
            runValidationTasks("stripe_api_key", value);
          }
          setStripe_api_key(value);
        }}
        onBlur={() => runValidationTasks("stripe_api_key", stripe_api_key)}
        errorMessage={errors.stripe_api_key?.errorMessage}
        hasError={errors.stripe_api_key?.hasError}
        {...getOverrideProps(overrides, "stripe_api_key")}
      ></TextField>
      <TextField
        label="Stripe secret"
        isRequired={false}
        isReadOnly={false}
        value={stripe_secret}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret: value,
              createdAt,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.stripe_secret ?? value;
          }
          if (errors.stripe_secret?.hasError) {
            runValidationTasks("stripe_secret", value);
          }
          setStripe_secret(value);
        }}
        onBlur={() => runValidationTasks("stripe_secret", stripe_secret)}
        errorMessage={errors.stripe_secret?.errorMessage}
        hasError={errors.stripe_secret?.hasError}
        {...getOverrideProps(overrides, "stripe_secret")}
      ></TextField>
      <TextField
        label="Created at"
        isRequired={true}
        isReadOnly={false}
        type="datetime-local"
        value={createdAt && convertToLocal(new Date(createdAt))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt: value,
              updatedAt,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <TextField
        label="Updated at"
        isRequired={false}
        isReadOnly={false}
        type="datetime-local"
        value={updatedAt && convertToLocal(new Date(updatedAt))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              userId,
              userType,
              email,
              name,
              company,
              planLevel,
              subscriptionStatus,
              profilePicture,
              linkedInProfile,
              stripe_api_key,
              stripe_secret,
              createdAt,
              updatedAt: value,
            };
            const result = onChange(modelFields);
            value = result?.updatedAt ?? value;
          }
          if (errors.updatedAt?.hasError) {
            runValidationTasks("updatedAt", value);
          }
          setUpdatedAt(value);
        }}
        onBlur={() => runValidationTasks("updatedAt", updatedAt)}
        errorMessage={errors.updatedAt?.errorMessage}
        hasError={errors.updatedAt?.hasError}
        {...getOverrideProps(overrides, "updatedAt")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || userModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || userModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
